<template>
  <!-- 邀请管理 -->
  <div class="container">
    <div class="mainbox">
      <!-- 邀请管理 -->
      <div class="invitebox">
        <div class="invitetitle">邀请管理</div>
        <div class="inputbox">
          <div class="inputlabel">邀请人获得</div>
          <el-input
            placeholder="请输入"
            v-model="invite_obtained_coupon"
          ></el-input>
          <div class="midlabel">张次卡，有效期</div>
          <el-input
            placeholder="请输入"
            v-model="invite_obtained_coupon_day"
          ></el-input>
          <div class="lastlabel">天</div>
        </div>
        <div class="inputbox">
          <div class="inputlabel">被邀请人获得</div>
          <el-input
            placeholder="请输入"
            v-model="invite_be_obtained_coupon"
          ></el-input>
          <div class="midlabel">张次卡，有效期</div>
          <el-input
            placeholder="请输入"
            v-model="invite_be_obtained_coupon_day"
          ></el-input>
          <div class="lastlabel">天</div>
        </div>
      </div>
      <!-- 返佣比例 -->
      <div class="rebateRatiobox">
        <div class="rebateRatiotitl">福利官开通返佣</div>
        <div class="rebatebox">
          <div class="rebatelabel">一级福利官</div>
          <el-input
            placeholder="请输入"
            v-model="bonus_one_invite_amount"
          ></el-input>
          <div class="rebatelast">元</div>
        </div>
        <div class="rebatebox">
          <div class="rebatelabel">二级福利官</div>
          <el-input
            placeholder="请输入"
            v-model="bonus_two_invite_amount"
          ></el-input>
          <div class="rebatelast">元</div>
        </div>
      </div>
      <!-- 返佣比例 -->
      <div class="rebateRatiobox">
        <div class="rebateRatiotitl">返佣比例</div>
        <div class="rebatebox">
          <div class="rebatelabel">福利官比例</div>
          <el-input placeholder="请输入" v-model="bonus_rebate"></el-input>
          <div class="rebatelast">%</div>
        </div>
        <div class="rebatebox">
          <div class="rebatelabel">推荐官比例</div>
          <el-input placeholder="请输入" v-model="attache_rebate"></el-input>
          <div class="rebatelast">%</div>
        </div>
      </div>
      <!-- 实付比例 -->
      <div class="rebateRatiobox">
        <div class="rebateRatiotitl">实付门店佣金比例（不区分角色）</div>
        <div class="rebatebox">
          <div class="rebatelabel">佣金比例</div>
          <el-input placeholder="请输入" v-model="store_rebate"></el-input>
          <div class="rebatelast">%</div>
        </div>
      </div>
      <!-- 规则说明 -->
      <div class="rulebox">
        <div class="ruletitle">规则说明</div>
        <div class="contentbox">
          <div class="contentlabel">规则说明</div>
          <el-input
            type="textarea"
            :rows="10"
            placeholder="请输入内容"
            v-model="invite_rule_desc"
            resize="none"
          >
          </el-input>
        </div>
      </div>
      <div class="btns">
        <div class="cancel" @click="cancel">重置</div>
        <div class="submit" @click="submit">保存</div>
      </div>
    </div>
  </div>
</template>

<script>
import { invite, editinvite } from "@/api/api";
export default {
  data() {
    return {
      invite_obtained_coupon: "", //邀请人获得券
      invite_obtained_coupon_day: "", //邀请券有效期
      invite_be_obtained_coupon: "", //被邀请获得券
      invite_be_obtained_coupon_day: "", //被邀券有效期
      invite_rule_desc: "", //邀请规则
      bonus_rebate: "", //福利官返佣
      attache_rebate: "", //专员返佣
      bonus_one_invite_amount: "", //一级福利官
      bonus_two_invite_amount: "", //二级福利官
      store_rebate: "", //门店返佣比例
    };
  },
  created() {
    this.getinviteinfo();
  },
  methods: {
    async getinviteinfo() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await invite({ admin_id });
        console.log(res);
        this.invite_obtained_coupon = res.data[0].content;
        this.invite_obtained_coupon_day = res.data[1].content;
        this.invite_be_obtained_coupon = res.data[2].content;
        this.invite_be_obtained_coupon_day = res.data[3].content;
        this.bonus_one_invite_amount = res.data[7].content;
        this.bonus_two_invite_amount = res.data[8].content;
        this.invite_rule_desc = res.data[4].content;
        this.bonus_rebate = Number(res.data[5].content) * 100;
        this.attache_rebate = Number(res.data[6].content) * 100;
        this.store_rebate = Number(res.data[9].content) * 100;
      } catch (error) {
        console.log(error);
      }
    },
    cancel() {
      (this.invite_obtained_coupon = ""), //邀请人获得券
        (this.invite_obtained_coupon_day = ""), //邀请券有效期
        (this.invite_be_obtained_coupon = ""), //被邀请获得券
        (this.invite_be_obtained_coupon_day = ""), //被邀券有效期
        (this.invite_rule_desc = ""), //邀请规则
        (this.bonus_rebate = ""), //福利官返佣
        (this.attache_rebate = ""); //专员返佣
      this.bonus_one_invite_amount = ""; //一级福利官固定金额
      this.bonus_two_invite_amount = ""; //二级福利官固定金额
    },
    async submit() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await editinvite({
          admin_id,
          invite_obtained_coupon: this.invite_obtained_coupon,
          invite_obtained_coupon_day: this.invite_obtained_coupon_day,
          invite_be_obtained_coupon: this.invite_be_obtained_coupon,
          invite_be_obtained_coupon_day: this.invite_be_obtained_coupon_day,
          bonus_one_invite_amount: this.bonus_one_invite_amount,
          bonus_two_invite_amount: this.bonus_two_invite_amount,
          invite_rule_desc: this.invite_rule_desc,
          bonus_rebate: Number(this.bonus_rebate) / 100,
          attache_rebate: Number(this.attache_rebate) / 100,
          store_rebate: Number(this.store_rebate) / 100,
        });
        this.$message({
          type: "success",
          message: "保存成功！",
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  min-height: 80vh;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
  .invitetitle {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 22px;
    margin-bottom: 20px;
  }
  .invitebox {
    padding-bottom: 24px;
    border-bottom: 1px solid #eeeeee;
    .inputbox {
      margin-bottom: 24px;
      display: flex;
      .inputlabel {
        width: 100px;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
        text-align: right;
        margin-right: 20px;
      }
      .midlabel {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
        margin-right: 8px;
        margin-left: 8px;
      }
      .lastlabel {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
        margin-left: 8px;
      }
    }
  }
  .rebateRatiobox {
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 20px;
    .rebateRatiotitl {
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #000000;
      margin-bottom: 20px;
    }
    .rebatebox {
      display: flex;
      margin-bottom: 20px;
      .rebatelabel {
        width: 100px;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
        text-align: right;
        margin-right: 20px;
      }
      .rebatelast {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
        margin-left: 8px;
      }
    }
  }

  .rulebox {
    .ruletitle {
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 22px;
      margin-bottom: 20px;
    }
    .contentbox {
      display: flex;
      .contentlabel {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 20px;
        margin-right: 20px;
        margin-top: 8px;
      }
    }
  }

  .btns {
    display: flex;
    position: absolute;
    bottom: 24px;
    left: 45%;
    .cancel {
      width: 92px;
      height: 36px;
      background: #ffffff;
      opacity: 1;
      border: 1px solid #dddddd;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #dddddd;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
      border-radius: 5px;
    }
    .submit {
      margin-left: 12px;
      width: 92px;
      height: 36px;
      background: #0073ff;
      opacity: 1;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
      border-radius: 5px;
    }
  }
}

::v-deep .el-input {
  width: 210px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
}
::v-deep .el-textarea {
  width: 576px;
  height: 248px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
}
</style>
