<template>
  <!-- 充值余额管理 -->
  <div class="container">
    <router-view
      v-if="
        this.$route.path == '/other/Rechargebalance/Rechargebalancedec' ||
        this.$route.path == '/other/Rechargebalance/addRechargebalance'
      "
    ></router-view>
    <div class="mainbox" v-else>
      <div class="maintitle">充值余额管理</div>
      <div class="searchbox">
        <div class="searchlabel">创建时间</div>
        <el-date-picker
          type="daterange"
          range-separator="至"
          start-placeholder="开始时间"
          end-placeholder="结束时间"
          v-model="time"
        >
        </el-date-picker>
        <div class="searchbtns">
          <div class="clear" @click="clear">清除</div>
          <div class="search" @click="search">搜索</div>
        </div>
      </div>
      <div class="newmoney" @click="tonewmoney">新建金额</div>
      <!-- 轮播列表 -->
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="recharge_price" label="充值金额(元)">
        </el-table-column>
        <el-table-column prop="gift_price" label="赠送金额(元)" align="center">
        </el-table-column>
        <el-table-column prop="add_time" label="创建时间" align="center">
        </el-table-column>
        <el-table-column prop="handel" label="操作" align="center">
          <template slot-scope="scope">
            <div class="orderdec" @click="tomoneydec(scope.row.id)">查看</div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background layout="prev, pager, next" :total="total" @current-change="handleCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { RechargebalanceList } from "@/api/api";
import { formatDate } from "@/utils/filters";
export default {
  data() {
    return {
      tableData: [],
      time: "",
      p: 1,
      total: 0,
    };
  },
  created() {
    this.getRechargebalanceList();
  },
  watch: {
    $route(to, from) {
      if (to.name === "Rechargebalance") {
        this.getRechargebalanceList();
      }
    },
  },
  methods: {
    // 查看详情
    tomoneydec(id) {
      this.$router.push({
        path: "/other/Rechargebalance/Rechargebalancedec",
        query: { id: id },
      });
    },
    // 新建充值金额
    tonewmoney() {
      if (this.tableData.length >= 6) {
        this.$message({
          type: "warning",
          message: "充值余额最多6条",
        });
      } else {
        this.$router.push("/other/Rechargebalance/addRechargebalance");
      }
    },
    // 获取充值金额列表
    async getRechargebalanceList() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await RechargebalanceList({ admin_id, p: this.p });
        console.log(res);
        this.tableData = res.data.list;
        this.total = Number(res.data.count);
      } catch (error) {
        console.log(error);
      }
    },
    // 搜索
    async search() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await RechargebalanceList({
          admin_id,
          p: this.p,
          start_time: formatDate(this.time[0]),
          end_time: formatDate(this.time[1]),
        });
        console.log(res);
        this.tableData = res.data.list;
        this.total = Number(res.data.count);
      } catch (error) {
        console.log(error);
      }
    },
    // 清除
    clear() {
      this.time = "";
      this.getRechargebalanceList();
    },
       //分页
       handleCurrentChange(val) {
      this.p = val;
      this.getRechargebalanceList();
    },
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  min-height: 80vh;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  box-sizing: border-box;
  padding: 20px;
  margin-bottom: 20px;
  position: relative;
  .maintitle {
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 36px;
    margin-bottom: 20px;
  }
  .searchbox {
    display: flex;
    padding-bottom: 20px;
    border-bottom: 1px solid #eeeeee;
    .searchlabel {
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 36px;
      margin-right: 8px;
    }

    .searchbtns {
      display: flex;
      margin-left: 65px;
      .clear {
        width: 78px;
        height: 36px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #dddddd;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #dddddd;
        line-height: 36px;
        text-align: center;
        margin-right: 16px;
        cursor: pointer;
      }
      .search {
        width: 78px;
        height: 36px;
        background: #0073ff;
        border-radius: 4px 4px 4px 4px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
      }
    }
  }

  .newmoney {
    width: 88px;
    height: 36px;
    background: #0073ff;
    border-radius: 4px 4px 4px 4px;
    text-align: center;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 36px;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .orderdec {
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #2b6df9;
    cursor: pointer;
  }
}

::v-deep .el-table thead tr > th {
  background-color: #f5f5f5;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
}
::v-deep .el-pagination {
  position: absolute;
  bottom: 20px;
  right: 0;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled) {
  background-color: #fff; // 进行修改未选中背景和字体// color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #1890ff; // 进行修改选中项背景和字体color: #fff;
}
</style>
