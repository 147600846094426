<template>
  <!-- 门店管理 -->
  <div class="container">
    <router-view v-if="this.$route.path === '/shop/shopindex/addshop' ||
      this.$route.path === '/shop/shopindex/editshop'
      "></router-view>
    <div class="mainbox" v-else>
      <div class="title">门店管理</div>
      <!-- 搜索 -->
      <div class="searchbox">
        <div class="leftsearchbox">
          <div class="storenamebox">
            门店名称
            <el-input placeholder="请输入门店名称" v-model="searchForm.title">
              <i slot="prefix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </div>
          <div class="storedatebox">
            创建时间
            <el-date-picker v-model="searchForm.time" type="daterange" range-separator="至" start-placeholder="开始时间"
              end-placeholder="结束时间">
            </el-date-picker>
          </div>
        </div>
        <div class="searchbtns">
          <div class="reset" @click="clear">清除</div>
          <div class="search" @click="search">搜索</div>
        </div>
      </div>
      <!-- 新建门店 -->
      <div class="addstorebtn" @click="toaddshop">新建门店</div>
      <!-- 门店列表 -->
      <div class="tablebox">
        <el-table :data="storetableData">
          <el-table-column prop="name" label="门店名称">
            <template slot-scope="scope">
              <div class="storenamediv">
                <img :src="$const.fileUrl + scope.row.store_img" alt="" class="storeimg" />
                <div class="storenametext">{{ scope.row.store_name }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="store_address" label="地址" show-overflow-tooltip align="center">
          </el-table-column>
          <el-table-column prop="add_time" label="创建时间" align="center">
          </el-table-column>
          <el-table-column label="操作" align="center">
            <template slot-scope="scope">
              <div class="handle">
                <div class="edit" @click="toeditshop(scope.row.id)">编辑</div>
                <div>/</div>
                <div class="del" @click="delshop(scope.row.id)">删除</div>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <!-- 分页 -->
      <el-pagination background layout="prev, pager, next" :total="total" @current-change="handleCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { shopList, delshop } from "@/api/api";
import { formatDate } from "@/utils/filters";
export default {
  data() {
    return {
      searchForm: {
        title: "",
        time: "",
      },
      p: 1,
      z: 6,
      total: 0,
      storetableData: [],
    };
  },
  watch: {
    $route(to, from) {
      if (to.name === "shop") {
        this.getshopList();
      }
    },
  },
  created() {
    this.getshopList();
  },
  methods: {
    // 新增门店
    toaddshop() {
      this.$router.push("/shop/shopindex/addshop");
    },
    //编辑门店
    toeditshop(id) {
      this.$router.push({
        path: "/shop/shopindex/editshop",
        query: { id: id },
      });
    },
    // 删除门店
    async delshop(id) {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await delshop({ admin_id, store_id: id });
        console.log(res);
        this.getshopList();
      } catch (error) {
        console.log(error);
      }
    },
    // 门店列表
    async getshopList() {
      const admin_id = sessionStorage.getItem("admin_id");
      try {
        const res = await shopList({ admin_id, p: this.p });
        console.log(res);
        this.storetableData = res.data.list;
        this.total = Number(res.data.count);
      } catch (error) {
        console.log(error);
      }
    },
    // 清除
    clear() {
      this.searchForm = {
        title: "",
        time: "",
      };
      this.getshopList();
    },
    // 搜索
    async search() {
      const admin_id = sessionStorage.getItem("admin_id");
      try {
        if (this.searchForm.time != "") {
          this.searchForm.time[0] = formatDate(this.searchForm.time[0]);
          this.searchForm.time[1] = formatDate(this.searchForm.time[1]);
        }
        const res = await shopList({
          admin_id,
          p: this.p,
          z: this.z,
          store_name: this.searchForm.title,
          start_time: this.searchForm.time[0],
          end_time: this.searchForm.time[1],
        });
        console.log(res);
        this.storetableData = res.data.list;
        this.total = Number(res.data.count);
      } catch (error) {
        console.log(error);
      }
    },
    //分页
    handleCurrentChange(val) {
      this.p = val;
      this.getshopList();
    },
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  width: 100%;
  min-height: 85vh;
  padding: 20px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  box-sizing: border-box;
  position: relative;

  .title {
    font-size: 20px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #000000;
    text-align: left;
    margin-bottom: 20px;
  }

  .searchbox {
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid #eeeeee;

    .leftsearchbox {
      width: 50%;
      display: flex;
      justify-content: space-between;
    }

    .storenamebox {
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #333333;

      .el-input {
        width: 240px;
        height: 36px;
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        margin-left: 8px;
      }
    }

    .storedatebox {
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #333333;

      .el-date-editor {
        width: 320px;
        height: 36px;
        background: #ffffff;
        border-radius: 4px 4px 4px 4px;
        margin-left: 8px;
      }
    }

    .searchbtns {
      display: flex;

      .reset {
        width: 78px;
        height: 36px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #dddddd;
        line-height: 36px;
        text-align: center;
        margin-right: 16px;
        font-size: 14px;
        font-weight: 400;
        color: #dddddd;
        cursor: pointer;
      }

      .search {
        width: 78px;
        height: 36px;
        background: #0073ff;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        line-height: 36px;
        text-align: center;
        font-size: 14px;
        color: #ffffff;
        cursor: pointer;
      }
    }
  }

  .addstorebtn {
    width: 88px;
    height: 36px;
    background: #0073ff;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
    color: #ffffff;
    line-height: 36px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }

  .tablebox {
    width: 100%;
  }

  .storenamediv {
    display: flex;
    padding-left: 20px;
  }

  .storenametext {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    line-height: 46px;
    margin-left: 8px;
  }

  .handle {
    display: flex;
    justify-content: center;
    padding-left: 20px;
  }

  .storeimg {
    width: 46px;
    height: 46px;
  }

  .el-table {
    width: 100%;
  }

  .edit {
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #2b6df9;
    line-height: 20px;
    margin-right: 6px;
    cursor: pointer;
  }

  .del {
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #ff5733;
    line-height: 20px;
    margin-left: 6px;
    cursor: pointer;
  }
}

::v-deep .el-table {
  margin-bottom: 50px;
}

::v-deep .el-table thead tr>th {
  background-color: #f5f5f5;
  padding-left: 20px;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
}

::v-deep .el-pagination {
  position: absolute;
  bottom: 20px;
  right: 0;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled) {
  background-color: #fff; // 进行修改未选中背景和字体// color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #1890ff; // 进行修改选中项背景和字体color: #fff;
}
</style>
