<template>
  <!-- 承包商申请 -->
  <div class="container">
    <div class="mainbox">
      <div class="maintitle">承包商申请</div>
      <div class="searchbox">
        <div class="inputbox">
          <div class="inputlabel">申请用户</div>
          <el-input
            placeholder="请输入内容"
            prefix-icon="el-icon-search"
            v-model="name"
          >
          </el-input>
        </div>
        <div class="inputbox">
          <div class="inputlabel">申请时间</div>
          <el-date-picker
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            v-model="time"
          >
          </el-date-picker>
        </div>
        <div class="searchbtns">
          <div class="clear" @click="clear">清除</div>
          <div class="search" @click="search">搜索</div>
        </div>
      </div>
      <!-- 申请列表 -->
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="user_name" label="申请用户"> </el-table-column>
        <el-table-column prop="city_name" label="城市"> </el-table-column>
        <el-table-column prop="user_phone" label="手机号码" align="center">
        </el-table-column>
        <el-table-column prop="add_time" label="申请时间" align="center">
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background layout="prev, pager, next" :total="total" @current-change="handleCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { applyforcontractorList } from "@/api/api";
import { formatDate } from "@/utils/filters";
export default {
  data() {
    return {
      tableData: [],
      name: "",
      time: "",
      p: 1,
      total: 0,
    };
  },
  created() {
    this.getapplyforcontractorList();
  },
  methods: {
    clear() {
      this.name = "";
      this.time = "";
      this.getapplyforcontractorList();
    },
    async search() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await applyforcontractorList({
          admin_id,
          p: this.p,
          user_name: this.name,
          start_time: formatDate(this.time[0]),
          end_time: formatDate(this.time[1]),
        });
        console.log(res);
        this.total = Number(res.data.count);
        this.tableData = res.data.list;
      } catch (error) {
        console.log(error);
      }
    },
    async getapplyforcontractorList() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await applyforcontractorList({ admin_id, p: this.p });
        console.log(res);
        this.total = Number(res.data.count);
        this.tableData = res.data.list;
      } catch (error) {
        console.log(error);
      }
    },
       //分页
       handleCurrentChange(val) {
      this.p = val;
      this.getapplyforcontractorList();
    },
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  min-height: 80vh;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
  .maintitle {
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 25px;
    margin-bottom: 20px;
  }
  .searchbox {
    display: flex;
    padding-bottom: 23px;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 20px;
    .inputbox {
      display: flex;
      margin-right: 30px;
      .inputlabel {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-right: 8px;
        line-height: 36px;
      }
    }
    .searchbtns {
      display: flex;
      margin-left: 65px;
      .clear {
        width: 78px;
        height: 36px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #dddddd;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #dddddd;
        line-height: 36px;
        text-align: center;
        margin-right: 16px;
        cursor: pointer;
      }
      .search {
        width: 78px;
        height: 36px;
        background: #0073ff;
        border-radius: 4px 4px 4px 4px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
      }
    }
  }
}

::v-deep .el-input {
  width: 240px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
}

::v-deep .el-table thead tr > th {
  background-color: #f5f5f5;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
}
::v-deep .el-pagination {
  position: absolute;
  bottom: 20px;
  right: 0;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled) {
  background-color: #fff; // 进行修改未选中背景和字体// color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #1890ff; // 进行修改选中项背景和字体color: #fff;
}
</style>
