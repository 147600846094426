<template>
  <!-- 新建门店 -->
  <div class="container">
    <div class="mainbox">
      <div class="shoptype">
        <div class="typetitle">门店类型</div>
        <div class="choosetype">
          <el-radio v-model="radio" label="1">直营门店</el-radio>
          <el-radio v-model="radio" label="2">加盟门店</el-radio>
        </div>
      </div>
      <div class="addtitle">基本信息</div>
      <div class="formbox">
        <el-form ref="form" :model="form" :rules="rules">
          <el-form-item label="门店名称" prop="name">
            <el-input v-model="form.name" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="机器编号" prop="equipment_id">
            <el-input v-model="form.equipment_id" placeholder="请输入"></el-input>
          </el-form-item>
          <el-form-item label="门店照片" prop="shopimg">
            <el-upload class="avatar-uploader" action="/api/6510eb6963781" :show-file-list="false"
              :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
              <img v-if="form.shopimg" :src="$const.fileUrl + form.shopimg" class="addimg" />
              <img src="../../assets/shopimg/add.png" alt="" class="addimg" v-else />
            </el-upload>
          </el-form-item>
          <el-form-item label="门店地区" prop="address">
            <el-col :span="3">
              <el-form-item prop="province">
                <el-select popper-class="eloption" :popper-append-to-body="true" @change="getShiList(form.province)"
                  v-model="form.province" placeholder="省" clearable @clear="removeSheng()" v-loadmore="shengloadmore">
                  <el-option v-for="item in shengList" :key="item.code" :value="item.id" :label="item.name" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item prop="city">
                <el-select popper-class="eloption" :popper-append-to-body="true" @change="getAreaList(form.city)"
                  v-model="form.city" placeholder="市" v-loadmore="shiloadmore" clearable @clear="removeShi()">
                  <el-option v-for="item in shiList" :key="item.code" :value="item.id" :label="item.name" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="3">
              <el-form-item prop="area">
                <el-select popper-class="eloption" :popper-append-to-body="true" @change="$forceUpdate()"
                  v-model="form.area" v-loadmore="arealoadmore" placeholder="区" clearable>
                  <el-option v-for="(item) in areaList" :key="item.code" :value="item.id" :label="item.name" />
                </el-select>
              </el-form-item>
            </el-col>
          </el-form-item>
          <el-form-item label="门店地址" prop="shopaddress">
            <div class="address">
              <el-input placeholder="请输入地址描述" v-model="form.shopaddress"></el-input>
            </div>
          </el-form-item>
          <el-form-item label="门店经度" prop="shoplon">
            <div class="input-number">
              <el-input-number :max=180 :min=0 :precision=6 :step=0.0001 placeholder="请输入经度"
                v-model="form.shoplon"></el-input-number>
            </div>
          </el-form-item>
          <el-form-item label="门店纬度" prop="shoplat">
            <div class="input-number">
              <el-input-number :max=90 :min=0 precision="6" :step=0.0001 placeholder="请输入纬度"
                v-model="form.shoplat"></el-input-number>
            </div>
            <div class="inputTip">请使用<a target="_blank" href="https://lbs.qq.com/getPoint/">选点工具</a>确定经纬度</div>
          </el-form-item>
          <!-- <el-form-item label="二维码">
            <el-upload
              class="avatar-uploader"
              action="/api/6510eb6963781"
              :show-file-list="false"
              :on-success="handleAvatarSuccess1"
              :before-upload="beforeAvatarUpload1"
            >
              <img
                v-if="form.codeimg"
                :src="$const.fileUrl + form.codeimg"
                class="addimg"
              />
              <img
                src="../../assets/shopimg/add.png"
                alt=""
                class="addimg"
                v-else
              />
            </el-upload>
          </el-form-item> -->
        </el-form>
      </div>
      <div class="affiliationbox">
        <div class="affiliationtitle">关联角色</div>
        <div class="affiliation" v-if="radio == 1">
          <div class="affiliationlabel">门店承包商</div>
          <el-select v-model="form.contractor" filterable placeholder="请选择">
            <el-option v-for="item in contractorList" :key="item.id" :label="item.nickname" :value="item.id">
              <template>
                <div class="selectbox">
                  <img :src="$const.fileUrl + item.contractor_img" alt="" class="selectimg" />
                  <div class="selectname">
                    {{ item.nickname }}
                  </div>
                </div>
              </template>
            </el-option>
          </el-select>
        </div>
        <div class="affiliation" v-if="radio == 2">
          <div class="affiliationlabel">门店加盟商</div>
          <el-select v-model="form.allianceBusiness" filterable placeholder="请选择">
            <el-option v-for="item in allianceBusinessList" :label="item.nickname" :key="item.id" :value="item.id">
              <template>
                <div class="selectbox">
                  <img :src="$const.fileUrl + item.franchisee_img" alt="" class="selectimg" />
                  <div class="selectname">
                    {{ item.nickname }}
                  </div>
                </div>
              </template>
            </el-option>
          </el-select>
        </div>
        <div class="affiliation" v-for="(item, index) in form.firm" :key="index">
          <div class="affiliationlabel">关联厂商</div>
          <div class="choosefirmbox">
            <el-select v-model="item.id" filterable placeholder="请选择">
              <el-option v-for="item in firmList" :key="item.id" :label="item.nickname" :value="item.id">
                <template>
                  <div class="selectbox">
                    <img :src="$const.fileUrl + item.manufacturer_img" alt="" class="selectimg" />
                    <div class="selectname">
                      {{ item.nickname }}
                    </div>
                  </div>
                </template>
              </el-option>
            </el-select>
            <div class="upperLimit">
              返佣上限
              <el-input placeholder="请输入" v-model="item.ratio"></el-input>
              元
            </div>
          </div>
          <div class="delfirm" @click="deleteRow(item)" v-if="form.firm.length > 1">
            删除
          </div>
        </div>
      </div>
      <div class="addfirm" @click="addfirm">新增厂商</div>
      <div class="btns">
        <div class="cancel">取消</div>
        <div class="submit" @click="submitForm">保存</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  addshop,
  cityList,
  contractorList,
  manufacturerList,
  allianceBusinessList,
} from "@/api/api";
export default {
  data() {
    return {
      form: {
        name: "",
        shopaddress: "",
        shoplon: "",
        shoplat: "",
        equipment_id: "",
        shopimg: "",
        province: "",
        city: "",
        area: "",
        codeimg: "",
        firm: [
          {
            id: "",
            ratio: null, //返佣上限
          },
        ], //厂商
        contractor: "", //承包商
        allianceBusiness: "", //加盟商
      },
      radio: "1",
      num: 1,
      shengList: [],
      shengP: 1,
      AllshengList: [],
      shiList: [], //arr.default[0].code  北京市一个对象，其他省可有多个市
      shiP: 1,
      areaList: [],
      areaP: 1,
      rules: {
        name: [{ required: true, message: "请输入门店名称", trigger: "blur" }],
        equipment_id: [{ required: true, message: "请输入机器编号", trigger: "blur" }],
        shopimg: [
          { required: true, message: "请上传门店照片", trigger: "change" },
        ],
        province: [{ required: true, message: "请选择省", trigger: "change" }],
        city: [{ required: true, message: "请选择市", trigger: "change" }],
        area: [{ required: true, message: "请选择区", trigger: "change" }],
        /* shopaddress: [
          { required: true, message: "请输入门店地址", trigger: "blur" },
        ], */
      },
      // 承包商列表
      contractorList: [],
      contractorP: 1,
      // 厂商列表
      firmList: [],
      firmP: 1,
      // 加盟商列表
      allianceBusinessList: [],
      allianceBusinessP: 1,
    };
  },
  watch: {
    radio(newValue, oldValue) {
      this.form = {
        name: "",
        shopaddress: "",
        shopimg: "",
        province: "",
        city: "",
        area: "",
        codeimg: "",
        firm: [
          {
            id: "",
            ratio: null, //返佣上限
          },
        ], //厂商
        contractor: "", //承包商
        allianceBusiness: "", //加盟商
      };
    },
    "form.province"(newValue, oldValue) {
      // this.shengP = 1
      this.shiP = 1;
      this.areaP = 1;
      this.form.city = "";
      this.form.area = "";
      this.shiList = [];
      this.areaList = [];
    },
  },
  directives: {
    loadmore: {
      inserted(el, binding) {
        const dom = el.querySelector(
          ".el-select-dropdown .el-select-dropdown__wrap"
        );
        dom.addEventListener("scroll", function (e) {
          const condition =
            this.scrollHeight - this.scrollTop <= this.clientHeight;
          if (condition) {
            binding.value();
          }
        });
      },
    },
  },
  created() {
    this.getcityList();
    this.getcontractorList();
    this.getfirmList();
    this.getallianceBusinessList();
  },
  methods: {
    // 获取城市列表
    async getcityList() {
      try {
        const res = await cityList({ pid: 0, p: this.shengP });
        console.log(res);
        this.shengList = this.shengList.concat(res.data.list);
      } catch (error) {
        console.log(error);
      }
    },
    // 获得市
    async getShiList(shengName) {
      this.$forceUpdate();
      // this.form.city = "";
      // this.form.area = "";
      try {
        const res = await cityList({ pid: this.form.province, p: this.shiP });
        console.log(res);
        this.shiList = this.shiList.concat(res.data.list);
      } catch (error) {
        console.log(error);
      }
    },
    // 获得区
    async getAreaList(shiName) {
      this.$forceUpdate();
      // this.form.area = "";
      try {
        const res = await cityList({ pid: this.form.city, p: this.areaP });
        console.log(res);
        this.areaList = this.areaList.concat(res.data.list);
      } catch (error) {
        console.log(error);
      }
    },
    removeSheng(e) {
      console.log("删除省");
      this.$forceUpdate();
      // this.shengP = 1;
      this.shiP = 1;
      this.areaP = 1;
      this.form.province = "";
      this.form.city = "";
      this.form.area = "";
      this.shiList = [];
      this.areaList = [];
    },
    removeShi(e) {
      console.log("删除shi");
      // this.shengP = 1;
      this.shiP = 1;
      this.areaP = 1;
      this.$forceUpdate();
      this.form.city = "";
      this.form.area = "";
      this.areaList = [];
    },
    submitForm() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            const admin_id = sessionStorage.getItem("admin_id");
            if (this.radio === "1") {
              await addshop({
                admin_id,
                store_type: this.radio,
                store_name: this.form.name,
                equipment_id: this.form.equipment_id,
                store_img: this.form.shopimg,
                store_sheng: this.form.province,
                store_shi: this.form.city,
                store_qu: this.form.area,
                store_address: this.form.shopaddress,
                store_lon: this.form.shoplon,
                store_lat: this.form.shoplat,
                joining_id: this.form.contractor,
                manufacturer: JSON.stringify(this.form.firm),
              });
              this.$message({
                type: "success",
                message: "保存成功！",
              });
              setTimeout(() => {
                this.$router.back();
              }, 1000);
            } else if (this.radio === "2") {
              await addshop({
                admin_id,
                store_type: this.radio,
                store_name: this.form.name,
                store_img: this.form.shopimg,
                store_sheng: this.form.province,
                store_shi: this.form.city,
                store_qu: this.form.area,
                store_address: this.form.shopaddress,
                joining_id: this.form.allianceBusiness,
                manufacturer: JSON.stringify(this.form.firm),
              });
            } else {
              this.$message({
                type: "fail",
                message: "保存失败！门店类型未选择！",
              });
              return false;
            }
            this.$message({
              type: "success",
              message: "保存成功！",
            });
            setTimeout(() => {
              this.$router.back();
            }, 1000);
          } catch (error) {
            console.log(error);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    // 触底方法
    shengloadmore() {
      this.shengP++;
      this.getcityList();
    },
    shiloadmore() {
      this.shiP++;
      this.getShiList();
    },
    arealoadmore() {
      this.areaP++;
      this.getAreaList();
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    handleAvatarSuccess(res, file) {
      this.form.shopimg = file.response.data;
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    handleAvatarSuccess1(res, file) {
      this.form.codeimg = file.response.data;
    },
    beforeAvatarUpload1(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    // 新增厂商
    addfirm() {
      this.form.firm.push({
        id: "",
        ratio: "", //返佣上限
      });
    },
    // 删除厂商
    deleteRow(item) {
      let index = this.form.firm.indexOf(item);
      if (index !== -1 && this.form.firm.length > 1) {
        this.form.firm.splice(index, 1);
      }
    },
    // 获取承包商列表
    async getcontractorList() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await contractorList({ admin_id, p: this.contractorP });
        console.log(res);
        this.contractorList = this.contractorList.concat(res.data.list);
      } catch (error) {
        console.log(error);
      }
    },
    // 获取厂商列表
    async getfirmList() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await manufacturerList({ admin_id, p: this.firmP });
        console.log(res);
        this.firmList = this.firmList.concat(res.data.list);
      } catch (error) {
        console.log(error);
      }
    },
    // 获取加盟商列表
    async getallianceBusinessList() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await allianceBusinessList({
          admin_id,
          p: this.allianceBusinessP,
        });
        console.log(res);
        this.allianceBusinessList = this.allianceBusinessList.concat(
          res.data.list
        );
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  min-height: 80vh;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  padding: 20px;
  box-sizing: border-box;

  .shoptype {
    .typetitle {
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #000000;
      margin-bottom: 20px;
    }

    .choosetype {
      padding-bottom: 20px;
      border-bottom: 1px solid #eeeeee;
      margin-bottom: 20px;
    }
  }

  .addtitle {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 22px;
    margin-bottom: 20px;
  }

  .formbox {
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 20px;

    .inputTip {
      width: 200px;
      font-size: 14px;
      font-weight: 400;
      color: #e92b2b;
      line-height: 36px;
      margin-right: 20px;
      margin-left: 100px;
    }
  }

  .addimg {
    width: 120px;
    height: 120px;
    cursor: pointer;
  }

  .address {
    margin-left: 100px;
  }

  .input-number {
    width: 2.40625rem;
    margin-left: 100px;

    .el-input-number {
      width: 100%;
    }
  }
  .affiliationbox {
    .affiliationtitle {
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #000000;
      margin-bottom: 10px;
    }

    .affiliation {
      display: flex;
      margin-left: 20px;
      align-items: center;
      margin-bottom: 20px;

      .affiliationlabel {
        width: 80px;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #606266;
        line-height: 20px;
        margin-right: 20px;
      }

      .choosefirmbox {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #606266;
        line-height: 20px;

        .el-input {
          width: 146px;
          height: 36px;
          margin-left: 8px;
          margin-right: 8px;
        }
      }

      .delfirm {
        width: 80px;
        height: 36px;
        font-size: 14px;
        margin-left: 20px;
        background-color: red;
        color: #ffffff;
        line-height: 36px;
        text-align: center;
        border-radius: 8px;
        cursor: pointer;
      }
    }
  }

  .addfirm {
    width: 96px;
    height: 36px;
    background: #0073ff;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    line-height: 36px;
    margin-left: 120px;
    cursor: pointer;
  }

  .btns {
    margin-top: 32px;
    margin-left: 20px;
    display: flex;
    justify-content: center;

    .cancel {
      width: 92px;
      height: 36px;
      background: #ffffff;
      opacity: 1;
      border: 1px solid #dddddd;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #dddddd;
      line-height: 36px;
      text-align: center;
      border-radius: 8px;
      cursor: pointer;
    }

    .submit {
      width: 92px;
      height: 36px;
      background: #0073ff;
      opacity: 1;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
      text-align: center;
      margin-left: 12px;
      border-radius: 8px;
      cursor: pointer;
    }
  }
}

.selectbox {
  display: flex;
  align-items: center;

  .selectimg {
    width: 24px;
    height: 24px;
    border-radius: 50%;
    margin-right: 20px;
  }
}

::v-deep .el-input {
  width: 462px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
}

::v-deep .el-form-item__label {
  min-width: 100px;
  padding-right: 20px;
}

::v-deep .el-select {
  width: 146px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  margin-right: 12px;

  .el-input {
    width: 100%;
    height: 100%;
  }

}
</style>
