<template>
  <!-- 抖音次卡管理 -->
  <div class="container">
    <div class="mainbox">
      <div class="maintitle">抖音次卡管理</div>
      <div class="inputbox">
        <div class="inputlabel">兑换码数量</div>
        <el-input placeholder="请输入" v-model="form.num"></el-input>
      </div>
      <div class="inputbox">
        <div class="inputlabel">次卡有效期</div>
        <el-input placeholder="请输入" v-model="form.day"></el-input>
        <div class="inputlabel">天</div>
      </div>
      <div class="create" @click="create">生成</div>
      <div class="inputbox line">
        <div class="inputlabel">录入兑换码</div>
        <el-input placeholder="请输入" v-model="code"></el-input>
      </div>
      <div class="inputbox">
        <div class="inputlabel">次卡有效期</div>
        <el-input placeholder="请输入" v-model="codeday"></el-input>
      </div>
      <div class="create" @click="entering">录入</div>
    </div>
  </div>
</template>

<script>
import { addtiktokcard, entertiktokcard } from "@/api/api";
export default {
  data() {
    return {
      form: {
        num: "",
        day: "",
      },
      code: "",
      codeday: "",
    };
  },
  methods: {
    create() {
      if (this.form.num == "") {
        this.$message({
          type: "error",
          message: "请输入兑换码数量",
        });
      } else if (this.form.day == "") {
        this.$message({
          type: "error",
          message: "请输入年卡有效期",
        });
      } else {
        this.$alert("兑换码生产成功请完成导出", "生产成功", {
          confirmButtonText: "确认导出",
          callback: async (action) => {
            const admin_id = sessionStorage.getItem("admin_id");
            const url =
              this.$const.fileUrl +
              admin_id +
              "&num=" +
              this.form.num +
              "&day=" +
              this.form.day;
            window.open(url);
            this.$message({
              type: "success",
              message: "导出成功！",
            });
          },
        });
      }
    },
    async entering() {
      if (this.code == "") {
        this.$message({
          type: "error",
          message: "请输入录入兑换码",
        });
      } else if (this.codeday == "") {
        this.$message({
          type: "error",
          message: "请输入年卡有效期",
        });
      } else {
        try {
          const admin_id = sessionStorage.getItem("admin_id");
          const res = await entertiktokcard({
            admin_id,
            carcode: this.code,
            day: this.codeday,
          });
          console.log(res, "录入成功");
          this.$message({
            type: "success",
            message: "录入成功",
          });
        } catch (error) {
          this.$message({
            type: "error",
            message: error.msg,
          });
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  min-height: 80vh;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  box-sizing: border-box;
  padding: 20px;

  .maintitle {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 22px;
    margin-bottom: 20px;
  }

  .inputbox {
    display: flex;
    margin-bottom: 24px;

    .inputlabel {
      width: 70px;
      height: 20px;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 36px;
      margin-right: 20px;
    }
  }

  .create {
    width: 92px;
    height: 36px;
    background: #0073ff;
    opacity: 1;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 36px;
    text-align: center;
    margin-left: 90px;
    border-radius: 5px;
    cursor: pointer;
  }
}

::v-deep .el-input {
  width: 210px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  margin-right: 8px;
}

.line {
  margin-top: 24px;
  padding-top: 24px;
  border-top: 1px solid #eeeeee;
}
</style>
