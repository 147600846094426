<template>
    <div class="container">
        <div class="mainbox">
            <div class="addtitle">新建加盟商</div>
            <el-form :model="form" :rules="rules" ref="ruleForm" class="demo-ruleForm" :hide-required-asterisk="true">
                <el-form-item label="登录账号" prop="username">
                    <el-input v-model="form.username" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="登录密码" prop="password">
                    <el-input v-model="form.password" type="password" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="加盟商头像" prop="avator">
                    <el-upload class="avatar-uploader" action="/api/6510eb6963781" :show-file-list="false"
                        :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                        <img v-if="form.avator" :src="$const.fileUrl + form.avator" class="addimg" />
                        <img src="../../assets/shopimg/add.png" alt="" class="addimg" v-else />
                    </el-upload>
                </el-form-item>
                <el-form-item label="加盟商昵称" prop="name">
                    <el-input v-model="form.name" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model="form.phone" placeholder="请输入"></el-input>
                </el-form-item>
            </el-form>
            <!-- 取消、保存 -->
            <div class="btns">
                <div class="cancel" @click="cancel">取消</div>
                <div class="submit" @click="submitForm">保存</div>
            </div>
        </div>
    </div>
</template>

<script>
import { addallianceBusiness } from "@/api/api";
export default {
    data() {
        return {
            form: {
                username: "",
                password: "",
                avator: "",
                name: "",
                phone: "",
            },
            rules: {
                username: [
                    { required: true, message: "请输入登录账号", trigger: "blur" },
                ],
                password: [
                    { required: true, message: "请输入登录密码", trigger: "blur" },
                ],
                avator: [
                    { required: true, message: "请上传加盟商头像", trigger: "blur" },
                ],
                name: [
                    { required: true, message: "请输入加盟商昵称", trigger: "blur" },
                ],
                phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
            },
        };
    },
    methods: {
        submitForm() {
            this.$refs.ruleForm.validate(async (valid) => {
                try {
                    if (valid) {
                        const admin_id = sessionStorage.getItem("admin_id");
                        const res = await addallianceBusiness({
                            admin_id,
                            username: this.form.username,
                            password: this.form.password,
                            user_img: this.form.avator,
                            nickname: this.form.name,
                            user_phone: this.form.phone,
                        });
                        console.log(res);
                        this.$message({
                            type: "success",
                            message: "保存成功！",
                        });
                        setTimeout(() => {
                            this.$router.back();
                        }, 500);
                    } else {
                        console.log("error submit!!");
                        return false;
                    }
                } catch (error) {
                    this.$message({
                        type: 'error',
                        message: error.msg
                    })
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        handleAvatarSuccess(res, file) {
            this.form.avator = file.response.data;
        },
        beforeAvatarUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过 2MB!");
            }
            return isLt2M;
        },
        // 取消
        cancel() {
            this.$router.back();
        },
    },
};
</script>

<style lang="scss" scoped>
.mainbox {
    min-height: 80vh;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    box-sizing: border-box;
    padding: 20px;
    position: relative;

    .addtitle {
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #000000;
        margin-bottom: 20px;
    }

    .addimg {
        width: 120px;
        height: 120px;
        cursor: pointer;
    }

    .btns {
        display: flex;
        position: absolute;
        bottom: 24px;
        left: 45%;

        .cancel {
            width: 92px;
            height: 36px;
            background: #ffffff;
            opacity: 1;
            border: 1px solid #dddddd;
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 400;
            color: #dddddd;
            line-height: 36px;
            margin-right: 12px;
            text-align: center;
            border-radius: 8px;
            cursor: pointer;
        }

        .submit {
            width: 92px;
            height: 36px;
            background: #0073ff;
            opacity: 1;
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 36px;
            text-align: center;
            border-radius: 8px;
            cursor: pointer;
        }
    }
}

::v-deep .el-input {
    width: 420px;
    height: 36px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
}

::v-deep .el-form-item__label {
    width: 100px;
}
</style>
