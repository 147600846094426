<template>
  <div class="container">
    <div class="describebox">
      <div class="line"></div>
      <div class="logintitle">管理系统</div>
      <div class="tip1">Management System</div>
      <div class="tip2">自助洗车后台管理</div>
    </div>
    <div class="loginform">
      <div class="formtitle">欢迎登录</div>
      <el-form
        ref="form"
        :model="form"
        label-position="top"
        :rules="rules"
        :hide-required-asterisk="true"
      >
        <el-form-item label="用户账号" prop="username">
          <el-input v-model="form.username" placeholder="请输入用户账号">
            <i slot="prepend" class="iconfont icon-zhanghaodenglu"></i>
          </el-input>
        </el-form-item>
        <el-form-item label="登录密码" prop="password">
          <el-input v-model="form.password" placeholder="请输入登录密码" type="password">
            <i slot="prepend" class="iconfont icon-password"></i>
          </el-input>
        </el-form-item>
      </el-form>
      <div class="loginbtn" @click="login">登 录</div>
    </div>
  </div>
</template>

<script>
import {adminLogin} from '@/api/api'
export default {
  data() {
    return {
      form: {
        username: "",
        password: "",
      },
      rules: {
        username: [
          { required: true, message: "请输入用户账号", trigger: "blur" },
          {
            min: 3,
            max: 12,
            message: "长度在 3 到 12 个字符",
            trigger: "blur",
          },
        ],
        password: [
          { required: true, message: "请输入登录密码", trigger: "blur" },
          {
            min: 6,
            max: 18,
            message: "长度在 6 到 18 个字符",
            trigger: "blur",
          },
        ],
      },
    };
  },
  methods: {
    login() {
      this.$refs.form.validate(async(valid) => {
        if (valid) {
          try {
            const res = await adminLogin({
              username:this.form.username,
              password:this.form.password
            })
            console.log(res);
            sessionStorage.setItem("token",this.form.password)
            sessionStorage.setItem("admin_id",res.data.id)
            sessionStorage.setItem("Userimg",res.data.user_img)
            sessionStorage.setItem("Username",res.data.nickname)
            this.$router.push("/home")
          } catch (error) {
            this.$message({
              type:"error",
              message:error.msg
            })
            // console.log(error);
          }
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background: url("/src/assets/login/formbg.png");
  width: 100%;
  height: 100%;
  position: fixed;
  background-size: 100% 100%;
  .describebox {
    position: absolute;
    top: 160px;
    left: 86px;
    text-align: left;
    .line {
      width: 14px;
      height: 45px;
      background: #ffffff;
    }
    .logintitle {
      font-size: 96px;
      font-family: PingFang SC-Bold, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 134px;
    }
    .tip1 {
      font-size: 38px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 53px;
    }
    .tip2 {
      font-size: 28px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 39px;
    }
  }

  .loginform {
    position: absolute;
    top: 141px;
    right: 82px;
    background-color: #ffffff;
    padding-top: 48px;
    padding-left: 53px;
    padding-right: 53px;
    padding-bottom: 144px;
    text-align: left;
    .formtitle {
      font-size: 32px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 45px;
      letter-spacing: 1px;
      margin-bottom: 48px;
      text-align: center;
    }
    .el-input__inner {
      height: 62px;
      background: #ffffff;
      border-radius: 0px 4px 4px 0px;
      opacity: 1;
      border: 1px solid #dddddd;
      font-size: 20px;
      border-left: 0;
    }
    .icon-zhanghaodenglu {
      background: #f8f8f8;
      border-radius: 3px 3px 3px 3px;
      opacity: 1;
      font-size: 30px;
    }
    .icon-password {
      background: #f8f8f8;
      border-radius: 3px 3px 3px 3px;
      opacity: 1;
      font-size: 30px;
    }
    .loginbtn {
      width: 464px;
      height: 62px;
      background: #0073ff;
      border-radius: 6px 6px 6px 6px;
      font-size: 24px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 62px;
      text-align: center;
      cursor: pointer;
      margin-top: 49px;
    }
    .el-form-item__label {
      font-size: 20px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #979797;
      line-height: 28px;
      margin-bottom: 10px;
    }
  }
}
</style>
