<template>
  <div class="hello"></div>
</template>

<script>
export default {
  name: "HelloTest",
  created() {
    const id = this.$route.query.id
    this.$router.replace({
        path: "/people/welfareList/welfaredec",
        query: { id: id },
      });
  },
};
</script>

<style scoped></style>
