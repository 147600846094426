<template>
  <!-- 提现详情 -->
  <div class="container">
    <div class="mainbox">
      <!-- 申请详情 -->
      <div class="applybox">
        <div class="applytitle">申请详情</div>
        <div class="applydecbox">
          <div class="applyitem">
            <div class="label">用户昵称：</div>
            <div class="dec">{{ user_nickname }}</div>
          </div>
          <div class="applyitem">
            <div class="label">手机号码：</div>
            <div class="dec">{{ user_phone }}</div>
          </div>
          <div class="applyitem">
            <div class="label">申请时间：</div>
            <div class="dec">{{ add_time }}</div>
          </div>
          <div class="applyitem" v-if="withdraw_type == 2">
            <div class="label">开户行：</div>
            <div class="dec">{{ bank_name }}</div>
          </div>
        </div>
      </div>
      <!-- 提现信息 -->
      <div class="withdrawinfobox">
        <div class="widthdrawtitle">提现信息</div>
        <div class="applydecbox">
          <div class="applyitem">
            <div class="label">处理状态：</div>
            <div class="dec" v-if="withdraw_status == 1">待审核</div>
            <div class="dec" v-if="withdraw_status == 2">已完成</div>
            <div class="dec" v-if="withdraw_status == 3">已驳回</div>
          </div>
          <div class="applyitem">
            <div class="label">提现方式：</div>
            <div class="dec" v-if="withdraw_type == 1">支付宝</div>
            <div class="dec" v-if="withdraw_type == 2">银行卡</div>
          </div>
          <div class="applyitem">
            <div class="label">提现金额(元)：</div>
            <div class="dec">{{ withdraw_price }}</div>
          </div>
          <div class="applyitem">
            <div class="label">姓 名：</div>
            <div class="dec">{{ withdraw_name }}</div>
          </div>
          <div class="applyitem">
            <div class="label">收款账号：</div>
            <div class="dec">{{ withdraw_account }}</div>
          </div>
        </div>
      </div>
      <div class="applyitem" v-if="withdraw_status == 3">
        <div class="label">驳回原因：</div>
        <div class="dec">{{ withdraw_desc }}</div>
      </div>
      <!-- 按钮 -->
      <div class="btns" v-if="withdraw_status == 1">
        <div class="refuse" @click="dialogVisible = true">驳回</div>
        <div class="finish" @click="agree">完成</div>
      </div>
      <!-- 驳回原因 -->
      <el-dialog title="驳回原因" :visible.sync="dialogVisible" width="30%">
        <el-input
          type="textarea"
          :rows="6"
          placeholder="请输入驳回原因"
          v-model="textarea"
          resize="none"
        >
        </el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="dialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="resfuse">提 交</el-button>
        </span>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { withdrawdec, withdrawagree, withdrawrefuse } from "@/api/api";
export default {
  data() {
    return {
      dialogVisible: false,
      textarea: "",
      id: this.$route.query.id,
      user_nickname: "",
      user_phone: "",
      update_time: "",
      withdraw_type: "",
      withdraw_status: "",
      withdraw_price: "",
      withdraw_name: "",
      withdraw_account: "",
      add_time: "",
      bank_name: "",
      withdraw_desc:''
    };
  },
  created() {
    this.getwithdrawdec();
  },
  methods: {
    async getwithdrawdec() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await withdrawdec({ admin_id, wid: this.id });
        console.log(res);
        (this.user_nickname = res.data.user_nickname),
          (this.user_phone = res.data.user_phone),
          (this.update_time = res.data.update_time),
          (this.withdraw_type = res.data.withdraw_type),
          (this.withdraw_status = res.data.withdraw_status),
          (this.withdraw_price = res.data.withdraw_price),
          (this.withdraw_name = res.data.withdraw_name),
          (this.withdraw_account = res.data.withdraw_account),
          (this.add_time = res.data.add_time);
        this.bank_name = res.data.bank_name;
        this.withdraw_desc = res.data.withdraw_desc
      } catch (error) {
        console.log(error);
      }
    },
    async agree() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await withdrawagree({
          admin_id,
          wid: this.id,
        });
        this.$message({
          type: "success",
          message: "提现已通过！",
        });
        setTimeout(() => {
          this.$router.back();
        }, 1000);
      } catch (error) {
        console.log(error);
      }
    },
    async resfuse() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await withdrawrefuse({
          admin_id,
          wid: this.id,
          desc: this.textarea,
        });
        this.$message({
          type: "warning",
          message: "提现已拒绝！",
        });
        this.dialogVisible = false;
        setTimeout(() => {
          this.$router.back();
        }, 1000);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  min-height: 80vh;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
  .applybox {
    padding-bottom: 20px;
    border-bottom: 1px solid #eeeeee;
    .applytitle {
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 22px;
      margin-bottom: 20px;
    }
  }

  .applydecbox {
    display: flex;
    flex-wrap: wrap;
  }

  .applyitem {
    display: flex;
    width: 300px;
    margin-bottom: 16px;
    .label {
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
    }
    .dec {
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #666666;
      line-height: 22px;
    }
  }

  .withdrawinfobox {
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 20px;
    .widthdrawtitle {
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #000000;
      margin-bottom: 20px;
    }
  }

  .btns {
    position: absolute;
    bottom: 24px;
    left: 40%;
    display: flex;
    .refuse {
      width: 92px;
      height: 36px;
      background: #ffffff;
      opacity: 1;
      border: 1px solid #ff5733;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #ff5733;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
      margin-right: 12px;
    }
    .finish {
      width: 92px;
      height: 36px;
      background: #0073ff;
      opacity: 1;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>
