<template>
  <div class="container">
    <el-row class="tac" style="height: 100%">
      <el-col style="height: 100%">
        <el-scrollbar style="height: 100%">
          <el-menu class="el-menu-vertical-demo" @open="handleOpen" @select="handleSelect" @close="handleClose"
            text-color="#fff" :default-active="activePath" active-text-color="#fff" unique-opened>
            <div class="title">
              <img src="../../assets/layoutimg/sidebartitle.png" alt="" />
            </div>
            <el-submenu v-for="item in subMenu" :key="item.path" :index="item.path">
              <template slot="title">
                <div @click="menuClick(item)">
                  <i :class="`iconfont ${item.icon}`"></i>
                  <span>{{ item.label }}</span>
                </div>
              </template>
              <el-menu-item-group v-for="subItem in item.children" :key="subItem.path">
                <el-menu-item :index="subItem.path" @click="menuClick(subItem)">{{ subItem.label }}</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
          </el-menu>
        </el-scrollbar>
      </el-col>
    </el-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      subMenu: [
        {
          path: "/home",
          name: "home",
          label: "首页",
          icon: "icon-home",
        },
        {
          path: "/shop/shopindex",
          name: "shop",
          label: "门店",
          icon: "icon-shop",
        },
        {
          path: "/service/serviceindex",
          name: "service",
          label: "服务",
          icon: "icon-car",
        },
        {
          path: "/bill/billindex",
          name: "order",
          label: "账单",
          icon: "icon-fuwufeizhangdanbaobiao",
          children: [
            {
              path: "/bill/TopUpBill",
              name: "TopUpBill",
              label: "充值账单",
            },
            {
              path: "/bill/welfareBill",
              name: "welfareBill",
              label: "福利官账单",
            },
            {
              path: "/bill/blanceChange",
              name: "blanceChange",
              label: "余额变动",
            },
          ],
        },
        {
          path: "/order/orderindex",
          name: "order",
          label: "订单",
          icon: "icon-order",
        },
        {
          path: "/people",
          name: "people",
          label: "人员",
          icon: "icon-people",
          children: [
            {
              path: "/people/peopleList",
              name: "peopleList",
              label: "用户列表",
            },
            {
              path: "/people/welfareList",
              name: "welfareList",
              label: "福利官列表",
            },
            {
              path: "/people/contractorList",
              name: "contractorList",
              label: "承包商列表",
            },
            {
              path: "/people/allianceBusinessList",
              name: "allianceBusinessList",
              label: "加盟商列表",
            },
            {
              path: "/people/manufacturerList",
              name: "manufacturerList",
              label: "厂商列表",
            },
            {
              path: "/people/cityAgency",
              name: "cityAgency",
              label: "城市代理",
            },
          ],
        },
        {
          path: "/promotion",
          name: "promotion",
          icon: "icon-discount",
          label: "促销",
          children: [
            {
              path: "/promotion/timecard?type=2",
              name: "timecard",
              label: "单次卡管理",
            },
            {
              path: "/promotion/timecard?type=1",
              name: "yearcard",
              label: "多次卡管理",
            },
            /* {
              path: "/promotion/TikTok",
              name: "TikTok",
              label: "抖音次卡",
            }, */
            {
              path: "/promotion/invite",
              name: "invite",
              label: "邀请管理",
            },
          ],
        },
        {
          path: "/role",
          name: "role",
          icon: "icon-role",
          label: "角色管理",
          children: [
            {
              path: "/role/welfaremanage",
              name: "welfaremanage",
              label: "福利官管理",
            },
            {
              path: "/role/contractormanage",
              name: "contractormanage",
              label: "承包商管理",
            },
            {
              path: "/role/allianceBusinessmanege",
              name: "allianceBusinessmanege",
              label: "加盟商管理",
            },
            {
              path: "/role/cityAgency",
              name: "cityAgency",
              label: "城市代理管理",
            },
          ],
        },
        {
          path: "/withdraw/withdrawindex",
          name: "withdraw",
          label: "提现申请",
          icon: "icon-wallet",
          children: [
            {
              path: "/withdraw/userApply",
              name: "userApply",
              label: "用户申请",
            },
            {
              path: "/withdraw/roleApply",
              name: "roleApply",
              label: "角色申请",
            },
          ],
        },
        {
          path: "/other",
          name: "other",
          icon: "icon-set",
          label: "其他",
          children: [
            {
              path: "/other/carousel",
              name: "carousel",
              label: "轮播图管理",
            },
            // {
            //   path: "/other/overtimepay",
            //   name: "overtimepay",
            //   label: "超时费管理",
            // },
            {
              path: "/other/applyforcontractor",
              name: "applyforcontractor",
              label: "承包商申请",
            },
            {
              path: "/other/applyforalliancebusiness",
              name: "applyforalliancebusiness",
              label: "加盟商申请",
            },
            {
              path: "/other/Rechargebalance",
              name: "Rechargebalance",
              label: "充值余额管理",
            },
            {
              path: "/other/balanceagreement",
              name: "balanceagreement",
              label: "用户服务协议",
            },
            {
              path: "/other/privacyagreement",
              name: "privacyagreement",
              label: "用户隐私协议",
            },
            {
              path: "/other/carwashnotice",
              name: "carwashnotice",
              label: "洗车注意事项",
            },
            {
              path: "/other/phone",
              name: "phone",
              label: "电话客服",
            },
            {
              path: "/other/meituansession",
              name: "meituansession",
              label: "美团授权",
            },
            {
              path: "/other/agreement",
              name: "agreement",
              label: "关于我们",
            },
          ],
        },
      ],
      activePath: "/home",
    };
  },
  methods: {
    handleOpen(key, keyPath) {
      console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      console.log(key, keyPath);
    },
    handleSelect(keyPath) {
      window.sessionStorage.setItem("activePath", keyPath);
    },
    menuClick(item) {
      // 当页面的路由与跳转的路由不一致才进行跳转
      if (
        this.$route.path !== item.path &&
        !(this.$route.path === "/home" && item.path === "/") &&
        !item.children
      ) {
        this.$router.push(item.path);
      }
    },
  },
  watch: {
    //路由改变的时候监听
    $route(to, form) {
      this.activePath = to.matched[1].path;
    },
  },
  created() {
    this.activePath = window.sessionStorage.getItem("activePath");
  },
};
</script>


<style lang="scss" scoped>
.container {
  height: 100%;
}

/* 隐藏滚动条 */
::-webkit-scrollbar {
  display: none;
}

/* 其他的滚动条样式，例如滚动条轨道的背景颜色 */
.wrap-scrollbar {
  /* 滚动条轨道的背景颜色 */
  background-color: #eee;

  /* 确保内容不会溢出滚动条 */
  overflow-y: scroll;
}

.el-menu-vertical-demo {
  width: 184px;
  height: 100%;
  // overflow-y:scroll;
  overflow-y: auto;
  position: fixed;
  background: #151f42 !important;

  .title {
    font-size: 22px;
    font-weight: 400;
    color: #ffffff;
    line-height: 40px;
    text-align: center;
  }

  .el-submenu.is-opened {
    background: #1890ff !important;
    // border-radius: 10px 0px 0px 10px;
    color: #fff !important;
    font-size: 16px;

    i {
      color: #fff !important;
    }
  }

  .el-menu-item.is-active {
    background: #1890ff !important;
    // border-radius: 10px 0px 0px 10px;
    color: #fff !important;
    font-size: 16px;

    i {
      color: #fff !important;
    }
  }

  .el-menu-item {
    font-size: 16px;
    min-width: 100%;
    background: #151f42;
  }

  ::-webkit-scrollbar {
    display: none !important;
  }

  ::v-deep .el-menu-item-group__title {
    height: 0;
    padding: 0;
  }

  .el-submenu__title i {
    color: #fff;
    font-size: 18px;
  }

  ::v-deep .el-icon-arrow-down:before {
    color: #fff;
  }

  .iconfont {
    margin-right: 10px;
    text-align: center;
  }

  ::v-deep .el-submenu.is-opened>.el-submenu__title .el-submenu__icon-arrow {
    -webkit-transform: rotateZ(0deg);
    transform: rotateZ(0deg);
  }

  ::v-deep .el-icon-arrow-down:before {
    content: "\e6e0";
  }

  ::v-deep .el-submenu__title:hover {
    background-color: #409eff;
  }

  ::v-deep .el-menu-item:hover {
    background-color: #409eff;
  }
}
</style>
