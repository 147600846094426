<template>
  <!-- 充值金额详情 -->
  <div class="container">
    <div class="mainbox">
      <div class="maintitle">充值金额详情</div>
      <div class="inputbox">
        <div class="inputlabel">充值金额</div>
        <el-input placeholder="请输入" v-model="recharge_price"></el-input>
        <div class="inputlabel">元</div>
      </div>
      <div class="inputbox">
        <div class="inputlabel">赠送金额</div>
        <el-input placeholder="请输入" v-model="gift_price"></el-input>
        <div class="inputlabel">元</div>
      </div>
      <div class="btns">
        <div class="cancel" @click="del">删除</div>
        <div class="submit" @click="submit">保存</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Rechargebalancedec,
  editRechargebalance,
  delRechargebalance,
} from "@/api/api";
export default {
  data() {
    return {
      id: this.$route.query.id,
      recharge_price: "",
      gift_price: "",
    };
  },
  created() {
    this.getRechargebalancedec();
  },
  methods: {
    async getRechargebalancedec() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await Rechargebalancedec({ admin_id, aid: this.id });
        console.log(res);
        this.recharge_price = res.data.recharge_price;
        this.gift_price = res.data.gift_price;
      } catch (error) {
        console.log(error);
      }
    },
    async del() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await delRechargebalance({ admin_id, aid: this.id });
        console.log(res);
        this.$message({
          type: "success",
          message: "删除成功！",
        });
        setTimeout(() => {
          this.$router.back();
        }, 500);
      } catch (error) {
        console.log(error);
      }
    },
    async submit() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await editRechargebalance({
          admin_id,
          aid: this.id,
          recharge_price: this.recharge_price,
          gift_price: this.gift_price,
        });
        console.log(res);
        this.$message({
          type: "success",
          message: "保存成功！",
        });
        setTimeout(() => {
          this.$router.back();
        }, 500);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  min-height: 80vh;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
  .maintitle {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #000000;
    margin-bottom: 20px;
  }
  .inputbox {
    display: flex;
    margin-bottom: 24px;
    .inputlabel {
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 36px;
    }
  }

  .btns {
    display: flex;
    position: absolute;
    bottom: 24px;
    left: 45%;
    .cancel {
      width: 92px;
      height: 36px;
      background: #ffffff;
      opacity: 1;
      border: 1px solid #ff5733;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #ff5733;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
      border-radius: 5px;
    }
    .submit {
      margin-left: 12px;
      width: 92px;
      height: 36px;
      background: #0073ff;
      opacity: 1;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
      border-radius: 5px;
    }
  }
}

::v-deep .el-input {
  width: 320px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  margin-left: 20px;
  margin-right: 8px;
}
</style>
