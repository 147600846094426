<template>
  <!-- 福利官详情 -->
  <div class="container">
    <div class="mainbox">
      <div class="decheader">
        <div class="dectitle">福利官详情</div>
        <div class="btns">
          <div class="freeze" v-if="user_status == 0">冻结福利官</div>
          <div class="relieve" v-else>解除冻结</div>
        </div>
      </div>
      <div class="decitembox">
        <div class="decitem">
          <div class="label">用户昵称：</div>
          <div class="dec">
            <img :src="user_img" alt="" />
            {{ nickname }}
          </div>
        </div>
        <div class="decitem">
          <div class="label">手机号码：</div>
          <div class="dec">{{ user_phone }}</div>
        </div>
        <div class="decitem">
          <div class="label">账户余额(元)：</div>
          <div class="dec">{{ balance }}</div>
          <div class="decbtn" @click="changepassword = true">修改</div>
        </div>
        <div class="decitem">
          <div class="label">关联门店：</div>
          <div class="dec">{{ store_name }}</div>
        </div>
        <div class="decitem">
          <div class="label">福利官有效期：</div>
          <div class="dec">{{ bonus_end_time }}</div>
        </div>
        <div class="decitem">
          <div class="label">年卡有效期：</div>
          <div class="dec">{{ bonus_end_time }}</div>
        </div>
        <div class="decitem">
          <div class="label">账户佣金(元)：</div>
          <div class="dec">{{ brokerage }}</div>
        </div>
        <div class="decitem">
          <div class="label">绑定门店：</div>
          <div class="dec" v-if="bind_store_name == null">暂未绑定门店</div>
          <div class="dec">{{ bind_store_name }}</div>
        </div>
        <div class="decitem">
          <div class="label">设置福利官：</div>
          <div class="setbtn" @click="cancelflg">取消福利官</div>
        </div>
      </div>

      <!-- 修改余额 -->
      <el-dialog
        title="修改余额"
        :visible.sync="changepassword"
        width="30%"
        center
      >
        <el-input
          placeholder="请输入修改后余额"
          v-model="newpassword"
        ></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="changepassword = false">取 消</el-button>
          <el-button type="primary" @click="changeword">确 定</el-button>
        </span>
      </el-dialog>

      <el-tabs v-model="activeName" @tab-click="handleClick">
        <el-tab-pane label="返佣记录" name="first" class="recordtitle"
          ><el-table :data="orderRebateList" style="width: 100%">
            <el-table-column prop="nickname" label="下单用户">
              <template slot-scope="scope">
                <div class="userinfo">
                  <img :src="scope.row.user_img" alt="" />
                  <div>{{ scope.row.nickname }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="user_phone" label="手机号码" align="center">
            </el-table-column>
            <el-table-column prop="type" label="服务类型" align="center">
              <template slot-scope="scope">
                <div v-if="scope.row.service_type == 1">快速清洗</div>
                <div v-if="scope.row.service_type == 2">水蜡深度清洗</div>
                <div v-if="scope.row.service_type == 3">自助水蜡深度精洗</div>
                <div v-if="scope.row.service_type == 4">开通福利官返佣</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="actual_paid"
              label="实付金额(元)"
              align="center"
            >
            </el-table-column>
            <el-table-column
              prop="rebate_price"
              label="返佣金额(元)"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="add_time" label="返佣时间" align="center">
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination
            background
            layout="prev, pager, next"
            :total="orderRebateCount"
            @current-change="handleCurrentChange"
          >
          </el-pagination
        ></el-tab-pane>
        <el-tab-pane label="团队成员" name="second" class="recordtitle"
          ><el-table :data="groupList" style="width: 100%">
            <el-table-column prop="nickname" label="用户昵称">
              <template slot-scope="scope">
                <div class="userinfo">
                  <img :src="scope.row.user_img" alt="" />
                  <div>{{ scope.row.nickname }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="user_phone" label="手机号码" align="center">
            </el-table-column>
            <el-table-column prop="store_name" label="关联门店" align="center">
            </el-table-column>
            <el-table-column
              prop="bons_open_time"
              label="开通时间"
              align="center"
            >
            </el-table-column>
            <el-table-column prop="user_count" label="下级人数" align="center">
            </el-table-column>
            <el-table-column prop="handle" label="操作" align="center">
              <template slot-scope="scope">
                <div class="peopledec" @click="towelfaredec(scope.row.user_id)">
                  查看
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination
            background
            layout="prev, pager, next"
            :total="groupCount"
            @current-change="grouphandleCurrentChange"
          >
          </el-pagination
        ></el-tab-pane>
      </el-tabs>

      <!-- 返佣记录 -->
      <!-- <div class="recordtitle">返佣记录</div> -->
    </div>
  </div>
</template>

<script>
import { welfareDec, groupList,changeuserbalance,cancelwelfare } from "@/api/api";
export default {
  data() {
    return {
      swval: false,
      p: 1,
      orderRebateList: [],
      id: this.$route.query.id,
      balance: "",
      bonus_end_time: "",
      brokerage: "",
      end_time: "",
      nickname: "",
      orderRebateCount: 0,
      store_name: "",
      user_img: "",
      user_phone: "",
      user_status: 0,
      changepassword: false,
      newpassword: "",
      activeName: "first",
      groupList: [],
      bind_store_name: "",
      groupCount: 0,
      groupP: 1,
    };
  },
  created() {
    this.getwelfareDec();
    this.getgroupList();
  },
  methods: {
    // 团队福利官详情
    towelfaredec(id) {
      this.$router.push({
        path: "/people/welfareList/kong",
        query: { id: id },
      });
    },
    // 取消福利官
    cancelflg() {
      this.$confirm("此操作将取消该福利官身份, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          try {
            const res = await cancelwelfare({user_id:this.id})
          this.$message({
            type: "success",
            message: "取消成功!",
          }); 
          setTimeout(() => {
            this.$router.back()
          }, 500);
          } catch (error) {
            console.log(error);
          }
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    //获取福利官详情
    async getwelfareDec() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await welfareDec({ admin_id, p: this.p, user_id: this.id });
        console.log(res);
        this.orderRebateList = res.data.orderRebateList;
        this.balance = res.data.balance;
        this.bonus_end_time = res.data.bonus_end_time;
        this.brokerage = res.data.brokerage;
        this.end_time = res.data.end_time;
        this.nickname = res.data.nickname;
        this.orderRebateCount = Number(res.data.orderRebateCount);
        this.store_name = res.data.store_name;
        this.user_img = res.data.user_img;
        this.user_phone = res.data.user_phone;
        this.bind_store_name = res.data.bind_store_name;
      } catch (error) {
        console.log(error);
      }
    },
    // 获取团队列表
    async getgroupList() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const ress = await groupList({
          admin_id,
          user_id: this.id,
          p: this.groupP,
        });
        console.log(ress, "团队列表成功");
        this.groupList = ress.data.list;
        this.groupCount = Number(ress.data.count);
      } catch (error) {
        console.log(error);
      }
    },
    handleClick(tab, event) {
      console.log(tab, event);
    },
    //分页
    handleCurrentChange(val) {
      this.p = val;
      this.getwelfareDec();
    },
    grouphandleCurrentChange(val) {
      this.groupP = val;
      this.getwelfareDec();
    },
    // 修改余额
    async changeword() {
      if (this.newpassword <= 0) {
        this.$message({
          message: "修改余额须大于零！",
          type: "error",
        });
      } else if (this.newpassword > this.balance) {
        this.type = 1;
        this.newpassword = this.newpassword - this.balance;
        if (this.newpassword == 0) {
          this.$message({
            message: "修改余额等于当前余额！",
            type: "warning",
          });
        } else {
          try {
            const admin_id = sessionStorage.getItem("admin_id");
            const res = await changeuserbalance({
              admin_id,
              uid: this.id,
              change_price: this.newpassword,
              type: this.type,
            });
            this.$message({
              message: "修改余额成功！",
              type: "success",
            });
            this.changepassword = false;
            this.getwelfareDec();
            console.log(res, "修改成功");
          } catch (error) {
            console.log(error);
          }
        }
      } else if (this.newpassword < this.balance) {
        this.type = 2;
        this.newpassword = this.balance - this.newpassword;
        if (this.newpassword == 0) {
          this.$message({
            message: "修改余额等于当前余额！",
            type: "warning",
          });
        } else {
          try {
            const admin_id = sessionStorage.getItem("admin_id");
            const res = await changeuserbalance({
              admin_id,
              uid: this.id,
              change_price: this.newpassword,
              type: this.type,
            });
            this.$message({
              message: "修改余额成功！",
              type: "success",
            });
            this.changepassword = false;
            this.getwelfareDec();
            console.log(res, "修改成功");
          } catch (error) {
            console.log(error);
          }
        }
      } else if (this.newpassword == this.balance) {
        this.$message({
          message: "修改余额等于当前余额！",
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  min-height: 80vh;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  padding: 20px;
  box-sizing: border-box;
  position: relative;
  .dectitle {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #000000;
    margin-bottom: 20px;
  }
  .decheader {
    display: flex;
    justify-content: space-between;
  }
  .btns {
    display: flex;
  }
  .freeze {
    width: 92px;
    height: 36px;
    background: #ffffff;
    opacity: 1;
    border: 1px solid #0073ff;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #0073ff;
    line-height: 36px;
    cursor: pointer;
    margin-right: 12px;
    text-align: center;
    border-radius: 5px;
  }

  .relieve {
    width: 92px;
    height: 36px;
    background: #ffffff;
    opacity: 1;
    border: 1px solid #ff5733;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #ff5733;
    line-height: 36px;
    cursor: pointer;
    margin-right: 12px;
    text-align: center;
    border-radius: 5px;
  }
  .decitembox {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 20px;
    border-bottom: 1px solid #eeeeee;
    .decitem {
      width: 25%;
      display: flex;
      align-items: center;
      margin-bottom: 17px;
      .setbtn {
        width: 80px;
        height: 34px;
        background: #ff5733;
        border-radius: 352px 352px 352px 352px;
        opacity: 1;
        font-size: 12px;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 34px;
        text-align: center;
        cursor: pointer;
      }
      .label {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
      }
      .dec {
        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);
        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 4px;
        }
      }
      .decbtn {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #0073ff;
        line-height: 22px;
        cursor: pointer;
        margin-left: 8px;
      }
    }
  }

  .recordtitle {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .userinfo {
    display: flex;
    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 6px;
    }
  }

  .peopledec {
    color: #0073ff;
    cursor: pointer;
  }

  ::v-deep .el-table thead tr > th {
    background-color: #f5f5f5;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 20px;
  }

  ::v-deep .el-dialog__header {
    .el-input {
      width: 242px;
      height: 28px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;
      .el-input__inner {
        width: 100%;
        height: 100%;
      }
      .el-input__icon {
        line-height: 28px;
      }
    }
  }
  ::v-deep .el-dialog__body {
    text-align: center;
    padding-bottom: 60px;
    position: relative;
    .el-pagination {
      position: absolute;
      bottom: 20px;
      left: 0%;
    }
  }
  ::v-deep .el-pagination {
    position: absolute;
    bottom: 20px;
    right: 0;
  }
  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff; // 进行修改未选中背景和字体// color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }
  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #1890ff; // 进行修改选中项背景和字体color: #fff;
  }
  ::v-deep .el-tabs__nav-wrap::after {
    background-color: #fff;
  }
  ::v-deep .el-tabs__content {
    padding-bottom: 50px;
  }
}
</style>
