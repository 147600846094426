<template>
  <!-- 充值账单 -->
  <div class="container">
    <div class="mainbox">
      <div class="ordertitle">充值账单</div>
      <div class="searchbox">
        <div class="searchitembox">
          <div class="searchitem">
            <div class="lebel">手机号码</div>
            <el-input placeholder="请输入手机号码" prefix-icon="el-icon-search" v-model="phone"></el-input>
          </div>
          <div class="searchitem">
            <div class="lebel">充值门店</div>
            <el-input placeholder="请输入充值门店" prefix-icon="el-icon-search" v-model="shop"></el-input>
          </div>
          <div class="searchitem">
            <div class="lebel">完成时间</div>
            <el-date-picker type="daterange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间"
              v-model="time">
            </el-date-picker>
          </div>
          <div class="searchitem">
            <div class="lebel">支付方式</div>
            <el-select placeholder="请选择支付方式" v-model="pay_type" clearable>
              <el-option :value="1" label="微信支付" />
              <el-option :value="2" label="支付宝支付" />
              <el-option :value="4" label="线下支付" />
              <el-option :value="5" label="抖音兑换" />
              <el-option :value="6" label="美团兑换" />
            </el-select>
          </div>
          <!-- <div class="searchitem">
            <div class="lebel">类型</div>
            <el-select placeholder="请选择" v-model="type">
              <el-option
                v-for="item in typeoptions"
                :key="item.label"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </div> -->
          <div class="searchbtns">
            <div class="clear" @click="clear">清除</div>
            <div class="search" @click="search">搜索</div>
          </div>
        </div>
      </div>
      <!-- 订单列表 -->
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="nickname" label="用户昵称" align="center">
        </el-table-column>
        <el-table-column prop="user_phone" label="手机号" align="center">
        </el-table-column>
        <el-table-column prop="recharge_price" label="充值金额(元)" align="center">
        </el-table-column>
        <el-table-column prop="gift_price" label="赠送金额(元)" align="center">
        </el-table-column>
        <el-table-column prop="store_name" label="充值门店" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.store_name == null">--</div>
            <div v-else>
              {{ scope.row.store_name }}
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="pay_type" label="支付方式" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.pay_type == 1">微信支付</div>
            <div v-if="scope.row.pay_type == 2">支付宝支付</div>
            <!-- <div v-if="scope.row.pay_type == 3">余额支付</div> -->
            <div v-if="scope.row.pay_type == 4">线下支付</div>
            <div v-if="scope.row.pay_type == 5">抖音兑换</div>
            <div v-if="scope.row.pay_type == 6">美团兑换</div>
          </template>
        </el-table-column>
        <el-table-column prop="add_time" label="时间" align="center">
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background layout="prev, pager, next" :total="total" @current-change="handleCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { billList } from "@/api/api";
import { formatDate } from "@/utils/filters";

export default {
  data() {
    return {
      p: 1,
      total: 0,
      tableData: [],
      time: [],
      phone: "",
      shop: "",
      pay_type: null,
      // type: 1,
      // typeoptions: [
      //   {
      //     label: "余额充值",
      //     value: 1,
      //   },
      //   {
      //     label: "开通福利官",
      //     value: 2,
      //   },
      // ],
    };
  },
  created() {
    this.getbillList();
  },
  // watch:{
  //   type() {
  //     this.getbillList()
  //   }
  // },
  methods: {
    // 获取订单列表
    async getbillList() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await billList({ admin_id, p: this.p, type: 1 });
        console.log(res);
        this.tableData = res.data.list;
        this.total = Number(res.data.count);
      } catch (error) {
        console.log(error);
      }
    },
    clear() {
      this.time = "";
      this.phone = "";
      this.store_name = ""
      // this.type = 1
      this.getbillList();
    },
    async search() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        if (this.time != "") {
          this.time[0] = formatDate(this.time[0]);
          this.time[1] = formatDate(this.time[1]);
        }
        const res = await billList({
          admin_id,
          p: this.p,
          start_time: this.time[0],
          end_time: this.time[1],
          phone: this.phone,
          type: 1,
          store_name: this.shop,
          pay_type: this.pay_type,
        });
        console.log(res);
        this.tableData = res.data.list;
        this.total = Number(res.data.count);
      } catch (error) {
        console.log(error);
      }
    },
    //分页
    handleCurrentChange(val) {
      this.p = val;
      this.search();
    },
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  min-height: 90vh;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  box-sizing: border-box;
  padding: 20px;
  position: relative;

  .searchbox {
    padding-bottom: 20px;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 20px;
  }
  .ordertitle {
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #000000;
    margin-bottom: 20px;
  }
  .searchitembox {
    display: flex;
    justify-content: space-between;
    align-items: unset;
    flex-wrap: wrap;
    .searchitem {
      display: flex;
      align-items: center;
      .lebel {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #000000;
        margin-right: 8px;
      }
    }
  }
  .searchbtns {
    display: flex;
    // margin-top: 20px;
    // margin-left: 65px;
    .clear {
      width: 78px;
      height: 36px;
      border-radius: 4px 4px 4px 4px;
      opacity: 1;
      border: 1px solid #dddddd;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #dddddd;
      line-height: 36px;
      text-align: center;
      margin-right: 16px;
      cursor: pointer;
    }
    .search {
      width: 78px;
      height: 36px;
      background: #0073ff;
      border-radius: 4px 4px 4px 4px;
      font-size: 14px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
    }
  }
  .orderdec {
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #2b6df9;
    cursor: pointer;
  }
}
::v-deep .el-input {
  width: 240px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
}
::v-deep .el-table thead tr > th {
  background-color: #f5f5f5;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
}
::v-deep .el-pagination {
  position: absolute;
  bottom: 20px;
  right: 0;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled) {
  background-color: #fff; // 进行修改未选中背景和字体// color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #1890ff; // 进行修改选中项背景和字体color: #fff;
}
</style>
