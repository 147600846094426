<template>
  <!-- 提现申请 -->
  <div class="container">
    <router-view
      v-if="this.$route.path == '/withdraw/userApply/withdrawdec'"
    ></router-view>
    <div class="mainbox" v-else>
      <div class="manititle">提现申请</div>
      <div class="searchbox">
        <div class="seacrchitem">
          <div class="label">手机号码</div>
          <el-input
            placeholder="请输入手机号码"
            prefix-icon="el-icon-search"
            v-model="phone"
          >
          </el-input>
        </div>
        <div class="seacrchitem">
          <div class="label">申请时间</div>
          <el-date-picker
            v-model="time"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </div>
        <div class="seacrchitem">
          <div class="label">处理状态</div>
          <el-select v-model="status" placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="seacrchitem">
          <div class="label">用户身份</div>
          <el-select v-model="role" placeholder="请选择">
            <el-option
              v-for="item in roleoptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="btns">
          <div class="clear" @click="clear">清除</div>
          <div class="search" @click="search">搜索</div>
        </div>
      </div>
      <!-- 申请列表 -->
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="user_nickname" label="用户昵称" align="center">
        </el-table-column>
        <el-table-column prop="user_phone" label="手机号码" align="center">
        </el-table-column>
        <el-table-column prop="withdraw_type" label="提现方式" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.withdraw_type == 1">支付宝</div>
            <div v-if="scope.row.withdraw_type == 2">银行卡</div>
          </template>
        </el-table-column>
        <el-table-column
          prop="withdraw_price"
          label="提现金额(元)"
          align="center"
        >
        </el-table-column>
        <el-table-column prop="user_type" label="用户身份" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.user_type == 1">推荐官</div>
            <div v-if="scope.row.user_type == 6">福利官</div>
          </template>
        </el-table-column>
        <el-table-column prop="add_time" label="申请时间" align="center">
        </el-table-column>
        <el-table-column prop="withdraw_status" label="处理状态" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.withdraw_status == 1">待审核</div>
            <div v-if="scope.row.withdraw_status == 3">已驳回</div>
            <div v-if="scope.row.withdraw_status == 2">已完成</div>
          </template>
        </el-table-column>
        <el-table-column prop="handle" label="操作" align="center">
          <template slot-scope="scope">
            <div class="peopledec" @click="towidthdrawdec(scope.row.id)">
              查看
            </div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination
        background
        layout="prev, pager, next"
        :total="total"
        @current-change="handleCurrentChange"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { withdrawList } from "@/api/api";
import { formatDate } from "@/utils/filters";
export default {
  data() {
    return {
      time: "",
      status: "",
      role: "",
      options: [
        {
          value: "1",
          label: "待审核",
        },
        {
          value: "3",
          label: "已驳回",
        },
        {
          value: "2",
          label: "已完成",
        },
      ],
      roleoptions: [
        {
          value: "1",
          label: "推荐官",
        },
        {
          value: "6",
          label: "福利官",
        },
      ],
      tableData: [],
      p: 1,
      total: 0,
      phone: "",
    };
  },
  created() {
    this.getwithdrawList();
  },
  methods: {
    towidthdrawdec(id) {
      this.$router.push({
        path: "/withdraw/userApply/withdrawdec",
        query: { id: id },
      });
    },
    async getwithdrawList() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await withdrawList({
          admin_id,
          p: this.p,
          u_type: 0,
        });
        console.log(res);
        this.tableData = res.data.list;
        this.total = Number(res.data.count);
      } catch (error) {
        console.log(error);
      }
    },
    clear() {
      this.name = "";
      this.time = "";
      this.status = "";
      this.role = "";
      this.getwithdrawList();
    },
    async search() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        if (this.time[0]) {
          var start_time = formatDate(this.time[0]);
        }
        if (this.time[1]) {
          var end_time = formatDate(this.time[1]);
        }
        let type = "";
        if (this.role == "") {
          type = 0;
        } else {
          type = this.role;
        }
        const res = await withdrawList({
          admin_id,
          p: this.p,
          name: this.name,
          status: this.status,
          start_time: start_time,
          end_time: end_time,
          u_type: type,
        });
        console.log(res, "res");
        this.tableData = res.data.list;
        this.total = Number(res.data.count);
      } catch (error) {
        console.log(error, "err");
      }
    },
    //分页
    handleCurrentChange(val) {
      this.p = val;
      this.getwithdrawList();
    },
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  min-height: 80vh;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
  .manititle {
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #000000;
    margin-bottom: 20px;
  }
  .searchbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 20px;
    .seacrchitem {
      display: flex;
      align-items: center;
      .label {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #000000;
        margin-right: 8px;
      }
    }
    .btns {
      display: flex;
      .clear {
        width: 78px;
        height: 36px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #dddddd;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #dddddd;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
      }
      .search {
        width: 78px;
        height: 36px;
        background: #0073ff;
        border-radius: 4px 4px 4px 4px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
        text-align: center;
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }

  .peopledec {
    color: #0073ff;
    cursor: pointer;
  }
}

::v-deep .el-input {
  width: 240px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
}

::v-deep .el-input {
  width: 240px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
}
::v-deep .el-table thead tr > th {
  background-color: #f5f5f5;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
}
::v-deep .el-pagination {
  position: absolute;
  bottom: 20px;
  right: 0;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled) {
  background-color: #fff; // 进行修改未选中背景和字体// color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #1890ff; // 进行修改选中项背景和字体color: #fff;
}
</style>
