<template>
  <!-- 个人信息 -->
  <div class="container">
    <div class="mainbox">
      <div class="maintitle">个人信息</div>
      <div class="infobox">
        <div class="label">登录账号：</div>
        <div class="dec">{{ name }}</div>
      </div>
      <div class="infobox">
        <div class="label">登录密码：</div>
        <div class="dec">
          <div class="deccontent">************</div>
          <div class="change" @click="changepassword = true">修改</div>
        </div>
        <!-- 修改密码 -->
        <el-dialog title="修改密码" :visible.sync="changepassword" width="30%" center>
          <el-input placeholder="请输入新密码" v-model="new_pwd"></el-input>
          <span slot="footer" class="dialog-footer">
            <el-button @click="changepassword = false">取 消</el-button>
            <el-button type="primary" @click="changepwd">确 定</el-button>
          </span>
        </el-dialog>
      </div>
      <div class="infobox">
        <div class="label">个人头像：</div>
        <div class="dec">
          <el-upload class="avatar-uploader" action="/api/6510eb6963781" :show-file-list="false"
            :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <img v-if="avator" :src="$const.fileUrl + avator" class="addimg" />
            <img src="../../assets/shopimg/add.png" alt="" class="addimg" v-else />
          </el-upload>
        </div>
      </div>
      <div class="infobox">
        <div class="label">个人昵称：</div>
        <div class="dec">
          <el-input v-model="nickname"></el-input>
        </div>
      </div>
      <div class="submit" @click="submit">保存</div>
    </div>
  </div>
</template>

<script>
import { admininfo, changeadminpwd, editadmin } from "@/api/api";
export default {
  data() {
    return {
      name: "",
      avator: "",
      nickname: "",
      changepassword: false,
      new_pwd: "",
    };
  },
  created() {
    this.getadmininfo();
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.avator = file.response.data;
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    // 获取信息
    async getadmininfo() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await admininfo({ admin_id });
        console.log(res);
        this.name = res.data.username;
        this.avator = res.data.user_img;
        this.nickname = res.data.nickname;
      } catch (error) {
        console.log(error);
      }
    },
    // 修改管理员密码
    async changepwd() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await changeadminpwd({
          admin_id,
          new_password: this.new_pwd,
        });
        console.log(res);
        this.$message({
          type: "success",
          message: "修改成功！",
        });
        setTimeout(() => {
          this.changepassword = false;
        }, 500);
      } catch (error) {
        console.log(error);
      }
    },
    // 保存
    async submit() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await editadmin({
          admin_id,
          nickname: this.nickname,
          user_img: this.avator,
        });
        console.log(res);
        this.$message({
          type: "success",
          message: "保存成功,请重新登录生效！",
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  min-height: 80vh;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  box-sizing: border-box;
  padding: 20px;

  .maintitle {
    font-size: 20px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #000000;
    margin-bottom: 24px;
  }

  .infobox {
    display: flex;
    margin-bottom: 24px;

    .label {
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #333333;
      margin-right: 4px;
      line-height: 36px;
    }

    .dec {
      display: flex;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 36px;

      .change {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #0073ff;
        margin-left: 8px;
        cursor: pointer;
      }

      .addimg {
        width: 120px;
        height: 120px;
      }
    }
  }

  .submit {
    width: 92px;
    height: 36px;
    background: #0073ff;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 36px;
    text-align: center;
    cursor: pointer;
    margin-top: 32px;
    margin-left: 70px;
  }
}

::v-deep .el-input {
  width: 420px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
}

::v-deep .el-dialog__header {
  .el-input {
    width: 242px;
    height: 28px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;

    .el-input__inner {
      width: 100%;
      height: 100%;
    }

    .el-input__icon {
      line-height: 28px;
    }
  }
}

::v-deep .el-dialog__body {
  text-align: center;
  padding-bottom: 60px;
}
</style>
