import axios from "axios";
axios.defaults.headers.post["Content-Type"] = "application/json;charset=utf-8";
const http = axios.create({
  //baseURL: "http://192.168.1.8:8084/api/",
  baseURL: "/api/",
  timeout: 5000, //响应时间
  // headers:{"Content-Type":"application/json;charset=utf-8"},
});

//拦截器  -请求拦截
// http.interceptors.request.use(config=>{
//   let token=localStorage.getItem('token');
//   if(token){
//     config.headers.token=token;
//     // config.headers ={
//       // 'token':token
//     // }
//   }
//   return config;
// },err=>{
//   return Promise.reject(err)
// })

// 拦截器  -响应拦截
http.interceptors.response.use(
  (res) => {
    if (res.data.code === 1) {
      return Promise.resolve(res.data);
    } else {
      return Promise.reject(res.data);
    }
  },
  (err) => {
    return Promise.reject(err);
  }
);

//整体导出
export default http;
