<template>
  <!-- 轮播详情 -->
  <div class="container">
    <div class="mainbox">
      <div class="maintitle">轮播详情</div>
      <div class="namebox">
        <div class="namelabel">轮播图名称</div>
        <el-input placeholder="请输入" v-model="form.name"></el-input>
      </div>
      <div class="namebox">
        <div class="namelabel">轮播图排序</div>
        <el-input type='number' maxLength='9' placeholder="数字越大越靠前" v-model.number="form.sort"></el-input>
        <div class="tip">注：数字越大越靠前</div>
      </div>
      <div class="uploadbox">
        <div class="uploadlabel">轮播图素材</div>
        <div class="upload">
          <el-upload class="avatar-uploader" action="/api/6510eb6963781" :show-file-list="false"
            :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
            <img v-if="form.img" :src="$const.fileUrl + form.img" class="addimg" />
            <img src="../../assets/carousel/upload.png" alt="" class="addimg" v-else />
          </el-upload>
          <div class="tip">注：轮播尺寸为 702*280 px</div>
        </div>
      </div>
      <!-- 轮播类型 -->
      <div class="namebox">
        <div class="namelabel">跳转类型</div>
        <div class="typeRadio">
          <el-radio-group v-model="form.contentType">
            <el-radio-button :label="0" size="large">显示内容</el-radio-button>
            <el-radio-button :label="1" size="large">跳转页面</el-radio-button>
          </el-radio-group>
        </div>
      </div>

      <!-- 轮播内容 -->
      <div class="carouselcontent" v-if="form.contentType == 0">
        <div class="contentlabel">轮播图内容</div>
        <div class="quill">
          <el-upload class="avatar-uploader quill-img" name="file" action="/api/6510eb6963781" :show-file-list="false"
            :on-success="quillImgSuccess" accept=".jpg,.jpeg,.png,.gif">
          </el-upload>
          <quill-editor v-model="form.content" ref="myQuillEditor" :options="editorOption" @blur="onEditorBlur($event)"
            @focus="onEditorFocus($event)" @change="onEditorChange($event)">
          </quill-editor>
        </div>
      </div>
      <!-- 跳转页面 -->
      <div class="namebox" v-if="form.contentType == 1">
        <div class="namelabel">页面地址</div>
        <el-input placeholder="小程序页面地址" v-model="form.pageUrl"></el-input>
      </div>
      <div class="btns">
        <div class="cancel" @click="del">删除</div>
        <div class="submit" @click="submit">保存</div>
      </div>
    </div>
  </div>
</template>

<script>
import { quillEditor } from "vue-quill-editor";
import { carouseldec, editcarousel, delcarousel } from "@/api/api";
export default {
  data() {
    return {
      form: {
        name: "",
        img: "",
        content: "",
        contentType: 0,
        pageUrl: "",
        sort: 50
      },
      content: "",
      editorOption: {
        theme: "snow", // 主题
        placeholder: "",
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"], // 加粗，斜体，下划线，删除线
              ["blockquote"], // 引用
              [{ header: 1 }, { header: 2 }], // 标题，键值对的形式；1、2表示字体大小
              [{ list: "ordered" }, { list: "bullet" }], //列表
              [{ indent: "-1" }, { indent: "+1" }], // 缩进
              [{ direction: "rtl" }], // 文本方向
              [{ size: ["small", false, "large", "huge"] }], // 字体大小
              [{ header: [1, 2, 3, 4, 5, 6, false] }], //几级标题
              [{ color: [] }, { background: [] }], // 字体颜色，字体背景颜色
              [{ font: [] }], //字体
              [{ align: [] }], //对齐方式
              ["clean"], //清除字体样式
              // ['image']//上传图片、上传视频
              ["link", "image", "video"], // 链接、图片、视频
            ],
            handlers: {
              image: function (val) {
                if (val) {
                  document.querySelector(".quill-img input").click();
                } else {
                  this.quill.format("image", false);
                }
              },
              link: function (value) {
                if (value) {
                  var href = prompt("请输入链接地址：");
                  this.quill.format("link", href);
                } else {
                  this.quill.format("link", false);
                }
              },
            },
          },
        },
      },
      id: this.$route.query.id,
    };
  },
  computed: {
    editor() {
      return this.$refs.myQuillEditor.quill;
    },
  },
  created() {
    this.getcarouseldec();
  },
  methods: {
    handleAvatarSuccess(res, file) {
      this.form.img = file.response.data;
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.$message.error("上传图片大小不能超过 2MB!");
      }
      return isLt2M;
    },
    onEditorReady(editor) {
      // 准备编辑器
    },
    onEditorBlur() {
      // 失去焦点事件
    },
    onEditorFocus() {
      // 获得焦点事件
    },
    onEditorChange() {
      // 内容改变事件
    },
    // 富文本编辑框图片上传
    quillImgSuccess(res, file) {
      // 获取富文本组件实例
      let quill = this.$refs.myQuillEditor.quill;
      // 如果上传成功
      if (res.code == "00001") {
        // 获取光标所在位置
        let length = quill.getSelection().index;
        // 插入图片 res.data为服务器返回的图片地址
        quill.insertEmbed(
          length,
          "image",
          this.$const.fileUrl + res.data
        ); // 这里的url是图片的访问路径不是真实物理路径
        // 调整光标到最后
        quill.setSelection(length + 1);
        console.log(res, "上传图片");
      } else {
        this.$message.error("图片插入失败");
      }
    },
    // 获取轮播详情
    async getcarouseldec() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await carouseldec({ admin_id, bid: this.id });
        console.log(res);
        this.form.name = res.data.banner_title;
        this.form.img = res.data.banner_img;
        this.form.content = this.escapeStringHTML(res.data.banner_content);
        this.form.contentType = res.data.content_type;
        this.form.pageUrl = res.data.page_url;
        this.form.sort = res.data.banner_sort;
      } catch (error) {
        console.log(error);
      }
    },
    // 保存
    async submit() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await editcarousel({
          admin_id,
          bid: this.id,
          name: this.form.name,
          img: this.form.img,
          content: this.escapeStringHTML(this.form.content),
          type: this.form.contentType,
          url: this.form.pageUrl,
          sort: this.form.sort
        });
        console.log(res);
        this.$message({
          type: "success",
          message: "保存成功！",
        });
        setTimeout(() => {
          this.$router.back();
        }, 500);
      } catch (error) {
        console.log(error);
      }
    },
    // 删除
    async del() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await delcarousel({ admin_id, bid: this.id });
        console.log(res);
        this.$message({
          type: "success",
          message: "删除成功！",
        });
        setTimeout(() => {
          this.$router.back();
        }, 500);
      } catch (error) {
        console.log(error);
      }
    },
    // // 转码
    // escapeStringHTML(content) {
    //   content = content.replace(/&lt;/g, "<");
    //   content = content.replace(/&gt;/g, ">");
    //   return content.replace(/<[^>]+>/g, "");
    // },
    // 富文本处理
    escapeStringHTML(str) {
      return str
        .replace(str ? /&(?!#?\w+;)/g : /&/g, "&amp;")
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, '"')
        .replace(/&#39;/g, "'")
        .replace(/&amp;nbsp;/g, "\u3000");
    },
  },
};
</script>

<style>
.ql-editor {
  min-height: 150px;
}

.ql-snow .ql-tooltip[data-mode="link"]::before {
  content: "请输入链接地址:";
}

.ql-snow .ql-tooltip.ql-editing a.ql-action::after {
  border-right: 0px;
  content: "保存";
  padding-right: 0px;
}

.ql-snow .ql-tooltip[data-mode="video"]::before {
  content: "请输入视频地址:";
}

.ql-snow .ql-picker.ql-size .ql-picker-label::before,
.ql-snow .ql-picker.ql-size .ql-picker-item::before {
  content: "14px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="small"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
  content: "10px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="large"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
  content: "18px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="huge"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="huge"]::before {
  content: "32px";
}

.ql-snow .ql-picker.ql-header .ql-picker-label::before,
.ql-snow .ql-picker.ql-header .ql-picker-item::before {
  content: "文本";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="1"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="1"]::before {
  content: "标题1";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="2"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="2"]::before {
  content: "标题2";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="3"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="3"]::before {
  content: "标题3";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="4"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="4"]::before {
  content: "标题4";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="5"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="5"]::before {
  content: "标题5";
}

.ql-snow .ql-picker.ql-header .ql-picker-label[data-value="6"]::before,
.ql-snow .ql-picker.ql-header .ql-picker-item[data-value="6"]::before {
  content: "标题6";
}

.ql-snow .ql-picker.ql-font .ql-picker-label::before,
.ql-snow .ql-picker.ql-font .ql-picker-item::before {
  content: "默认";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="SimSun"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="SimSun"]::before {
  content: "宋体";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="Microsoft-YaHei"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="Microsoft-YaHei"]::before {
  content: "微软雅黑";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="KaiTi"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="KaiTi"]::before {
  content: "楷体";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="FangSong"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="FangSong"]::before {
  content: "仿宋";
}

.ql-snow .ql-picker.ql-font .ql-picker-label[data-value="SimHei"]::before,
.ql-snow .ql-picker.ql-font .ql-picker-item[data-value="SimHei"]::before {
  content: "黑体";
}

.ql-toolbar.ql-snow+.ql-container.ql-snow {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.ql-toolbar.ql-snow {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.ql-snow .ql-stroke,
.ql-snow .ql-picker {
  color: #999;
  stroke: #999;
}

.ql-snow .ql-fill,
.ql-snow .ql-stroke.ql-fill {
  fill: #999;
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="12px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="12px"]::before {
  content: "12px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="14px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="14px"]::before {
  content: "14px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="16px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="16px"]::before {
  content: "16px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="20px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="20px"]::before {
  content: "20px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="24px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="24px"]::before {
  content: "24px";
}

.ql-snow .ql-picker.ql-size .ql-picker-label[data-value="36px"]::before,
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="36px"]::before {
  content: "36px";
}

.ql-container {
  font-size: 14px;
}
</style>

<style lang="scss" scoped>
.mainbox {
  min-height: 80vh;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  box-sizing: border-box;
  padding: 20px;
  position: relative;

  .maintitle {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 22px;
    margin-bottom: 20px;
  }

  .namebox {
    display: flex;
    margin-top: 20px;

    .namelabel {
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 36px;
      margin-right: 20px;
    }
  }

  .typeRadio {
    margin-left: 10px;
  }

  .uploadbox {
    display: flex;
    margin-top: 20px;

    .uploadlabel {
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      margin-right: 20px;
    }

    .addimg {
      width: 300px;
      height: 120px;
      background: #f7f8fa;
      border-radius: 4px 4px 4px 4px;
    }
  }

  .tip {
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #fd2424;
    margin-top: 8px;
  }

  .carouselcontent {
    display: flex;
    margin-top: 20px;

    .contentlabel {
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 20px;
      margin-right: 20px;
    }

    .quill {
      width: 600px;
    }
  }

  .btns {
    display: flex;
    position: absolute;
    bottom: 24px;
    left: 45%;

    .cancel {
      width: 92px;
      height: 36px;
      background: #ffffff;
      opacity: 1;
      border: 1px solid #ff5733;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #ff5733;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
      border-radius: 5px;
    }

    .submit {
      margin-left: 12px;
      width: 92px;
      height: 36px;
      background: #0073ff;
      opacity: 1;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
      border-radius: 5px;
    }
  }
}

.quill-editor ::v-deep.ql-container {
  min-height: 490px;
}

.ql-container {
  min-height: 500px;
}

::v-deep .el-input {
  width: 420px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
}
</style>
