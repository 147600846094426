<template>
    <div>
        <el-row class=".clearfix">
            <el-col class="nav">
                <div class="user">
                    <el-dropdown @command="handleCommand">
                        <div>
                            <img :src="$const.fileUrl + userimg" alt="" class="tx" />
                            <span class="username">{{ username }}</span>
                        </div>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="a">个人中心</el-dropdown-item>
                            <el-dropdown-item command="b">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </el-col>
        </el-row>
        <el-row class="breadcrumb">
            <xn-bread></xn-bread>
        </el-row>
    </div>
</template>

<script>
import xnBread from "./XnBread.vue";
export default {
    data() {
        return {
            username: "张三",
            userimg: "",
        };
    },
    components: {
        xnBread,
    },
    created() {
        this.username = sessionStorage.getItem("Username");
        this.userimg = sessionStorage.getItem("Userimg");
    },
    methods: {
        //返回
        // back() {
        //   this.$router.back();
        // },
        //个人信息
        touserinfo() {
            this.$router.push("/userinfo");
        },
        //退出登录
        loginout() {
            sessionStorage.clear();
            location.reload()
        },
        handleCommand(command) {
            if (command == 'a') {
                this.touserinfo()
            } else if (command == 'b') {
                this.loginout()
            }
        }
    },
};
</script>

<style lang="scss" scoped>
.nav {
    float: right;
    text-align: right;

    .user {
        display: inline-block;

        .back {
            width: 20px;
            height: 20px;
            margin-right: 32px;
            vertical-align: middle;
            cursor: pointer;
        }

        .tx {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            margin-right: 11px;
            vertical-align: middle;
            cursor: pointer;
        }

        .username {
            font-size: 18px;
            color: #999999;
            cursor: pointer;
        }
    }
}

.breadcrumb {
    height: 52px;
    background: #ffffff;
    border-radius: 0px 0px 0px 0px;
}

.clearfix::before,
.clearfix::after {
    content: "";
    display: table;
}

.clearfix::after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}

.el-dropdown-selfdefine {
    height: 45px;
}
</style>
