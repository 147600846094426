<template>
  <!-- 洗车卡详情 -->
  <div class="container">
    <div class="mainbox">
      <div class="maintitle">编辑洗车卡</div>
      <div class="inputbox">
        <div class="inputlabel">洗车卡名称</div>
        <el-input v-model="form.name"></el-input>
      </div>
      <div class="inputbox">
        <div class="inputlabel">有效日期</div>
        <el-input v-model="form.day"></el-input>
        <div class="inputlabel">天</div>
      </div>
      <div v-if="type == '1'" class="inputbox">
        <div class="inputlabel">兑换码</div>
        <div>{{ form.code }}</div>
      </div>
      <div v-if="type == '2'" class="inputbox">
        <div class="inputlabel">二维码</div>
        <img :src="$const.fileUrl + form.codeimg" alt="" class="codeimg" />
      </div>
      <div v-if="type == '1'" class="inputbox">
        <div class="inputlabel">使用次数</div>
        <el-input v-model="form.use_num"></el-input>
        <div class="inputlabel">次</div>
      </div>
      <div v-if="type == '2'" class="inputbox">
        <div class="inputlabel">领取人数</div>
        <el-input v-model="form.is_infinite"></el-input>
        <div class="inputlabel">人</div>
      </div>
      <div v-if="type == '2'" class="inputlabel" style="color: red; font-size: 14px;">注：-1代表不限制人数</div>
      <div class="inputbox">
        <div class="inputlabel">洗车卡状态</div>
        <el-switch v-model="value" active-color="#00BE20" inactive-color="#C5C5C5">
        </el-switch>
      </div>
      <div class="btns">
        <div class="cancel" @click="del">删除</div>
        <div class="submit" @click="submit">保存</div>
      </div>
    </div>
  </div>
</template>

<script>
import { timecarddec, edittimecard, deltimecard } from "@/api/api";
export default {
  data() {
    return {
      form: {
        name: "",
        day: "",
        codeimg: "",
        use_num: "",
        is_infinite: "",
        status: 1,
      },
      value: false,
      id: this.$route.query.id,
      type: this.$route.query.type,
    };
  },
  created() {
    this.gettimecarddec();
  },
  methods: {
    // 获取洗车卡详情
    async gettimecarddec() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await timecarddec({ admin_id, cid: this.id });
        console.log(res);
        this.form.name = res.data.card_name;
        this.form.day = res.data.card_days;
        this.form.code = res.data.card_code;
        this.form.use_num = res.data.card_num;
        this.form.is_infinite = res.data.is_infinite;
        this.form.codeimg = res.data.code_img;
        if (res.data.use_status == 1) {
          this.value = true;
        } else if (res.data.use_status == 2) {
          this.value = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 编辑洗车卡
    async submit() {
      try {
        if (this.value == false) {
          this.form.status = 2;
        } else {
          this.form.status = 1;
        }
        if (this.form.name == "") {
          this.$message({
            type: "error",
            message: "请输入洗车卡名称",
          });
        } else if (this.day == "") {
          this.$message({
            type: "error",
            message: "请输入洗车卡有效期",
          });
        } else {
          const admin_id = sessionStorage.getItem("admin_id");
          const res = await edittimecard({
            admin_id,
            cid: this.id,
            name: this.form.name,
            day: this.form.day,
            use_num: this.form.use_num,
            is_infinite: this.form.is_infinite,
            status: this.form.status,
          });
          console.log(res);
          this.$message({
            type: "success",
            message: "保存成功！",
          });
          setTimeout(() => {
            this.$router.back();
          }, 1000);
        }
      } catch (error) {
        console.log(error);
      }
    },
    // 删除洗车卡
    async del() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await deltimecard({ admin_id, cid: this.id });
        console.log(res);
        this.$message({
          type: "success",
          message: "删除成功！",
        });
        setTimeout(() => {
          this.$router.back();
        }, 1000);
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  min-height: 80vh;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  box-sizing: border-box;
  padding: 20px;
  position: relative;

  .maintitle {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #000000;
    margin-bottom: 20px;
  }

  .inputbox {
    display: flex;
    align-items: center;
    margin-bottom: 24px;

    .codeimg {
      width: 120px;
      height: 120px;
      border-radius: 0px 0px 0px 0px;
      opacity: 1;
    }

    .inputlabel {
      width: 70px;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 36px;
      margin-right: 20px;
    }
  }

  .btns {
    display: flex;
    position: absolute;
    bottom: 24px;
    left: 45%;

    .cancel {
      width: 92px;
      height: 36px;
      background: #ffffff;
      opacity: 1;
      border: 1px solid #ff5733;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #ff5733;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
    }

    .submit {
      margin-left: 12px;
      width: 92px;
      height: 36px;
      background: #0073ff;
      opacity: 1;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
    }
  }
}

::v-deep {
  .el-input {
    margin-right: 8px;
    width: 320px;
    height: 36px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
  }
}
</style>
