<template>
    <!-- 加盟商详情 -->
    <div class="container">
        <div class="mainbox">
            <div class="dectitle">
                <div class="titletext">加盟商详情</div>
                <div class="titlebtns">
                    <div class="del" @click="del">删除</div>
                </div>
            </div>
            <div class="decitembox">
                <div class="decitem">
                    <div class="label">加盟商昵称：</div>
                    <div class="dec">
                        <img :src="$const.fileUrl + form.franchisee_img" alt="" />
                        {{ form.nickname }}
                    </div>
                </div>
                <div class="decitem">
                    <div class="label">手机号码:</div>
                    <div class="dec">{{ form.franchisee_phone }}</div>
                </div>
                <div class="decitem">
                    <div class="label">创建时间:</div>
                    <div class="dec">{{ form.add_time }}</div>
                </div>
                <div class="decitem">
                    <div class="label">登录账号:</div>
                    <div class="dec">{{ form.username }}</div>
                </div>
                <div class="decitem">
                    <div class="label">登录密码:</div>
                    <div class="dec">
                        <div class="dectext">*********</div>
                        <div class="decbtn" @click="changepassword = true">修改</div>
                    </div>
                </div>
                <div class="decitem">
                    <div class="label">关联门店:</div>
                    <div class="decbtn" @click="openshop">查看门店</div>
                </div>
                <div class="decitem">
                    <div class="label">总计收益(元):</div>
                    <div class="dec" v-if="form.totalRevenue == null">0.00</div>
                    <div class="dec" v-else>{{ form.totalRevenue }}</div>
                </div>
                <div class="decitem">
                    <div class="label">账户余额(元):</div>
                    <div class="dec">{{ form.franchisee_money }}</div>
                </div>
            </div>
            <!-- 修改密码 -->
            <el-dialog title="修改密码" :visible.sync="changepassword" width="30%" center>
                <el-input placeholder="请输入新密码" v-model="newpassword"></el-input>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="changepassword = false">取 消</el-button>
                    <el-button type="primary" @click="changeword">确 定</el-button>
                </span>
            </el-dialog>
            <!-- 查看门店 -->
            <el-dialog :visible.sync="shopview" width="50%">
                <template slot="title">
                    <div class="shopviewheader">
                        <div class="shopviewtitle">关联门店</div>
                        <div class="shopviewsearchbox">
                            <el-input placeholder="请输入门店名称" prefix-icon="el-icon-search" v-model="shopsearchview">
                            </el-input>
                            <div class="searchshopviewbtns">
                                <div class="clear" @click="clear">清除</div>
                                <div class="search" @click="search">搜索</div>
                            </div>
                        </div>
                    </div>
                </template>
                <el-table :data="storetableData">
                    <el-table-column prop="name" label="门店名称">
                        <template slot-scope="scope">
                            <div class="storenamediv">
                                <img :src="$const.fileUrl + scope.row.store_img
                        " alt="" class="storeimg" />
                                <div class="storenametext">{{ scope.row.store_name }}</div>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column prop="store_address" label="地址" show-overflow-tooltip align="center">
                    </el-table-column>
                    <el-table-column prop="add_time" label="创建时间" align="center">
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template slot-scope="scope">
                            <div class="handle">
                                <div class="edit" @click="toshopdec(scope.row.id)">查看</div>
                            </div>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页 -->
                <el-pagination background layout="prev, pager, next" :total="totalshop"
                    @current-change="handleCurrentChangeshop">
                </el-pagination>
            </el-dialog>
            <!-- 门店收益 -->
            <div class="recordtitle">门店收益</div>
            <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="name" label="下单用户">
                    <template slot-scope="scope">
                        <div class="userinfo">
                            <img :src="scope.row.user_img" alt=""
                                v-if="scope.row.user_img == 'https://thirdwx.qlogo.cn/mmopen/vi_32/POgEwh4mIHO4nibH0KlMECNjjGxQUq24ZEaGT4poC6icRiccVGKSyXwibcPq4BWmiaIGuG1icwxaQX6grC9VemZoJ8rg/132'" />
                            <img :src="$const.fileUrl + scope.row.user_img" alt="" v-else />
                            <div>{{ scope.row.nickname }}</div>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="user_phone" label="手机号码" align="center">
                </el-table-column>
                <el-table-column prop="store_name" label="下单门店" align="center">
                </el-table-column>
                <el-table-column prop="service_type" label="服务类型" align="center">
                    <template slot-scope="scope">
                        <div v-if="scope.row.service_type == 1">
                            快速清洗
                        </div>
                        <div v-if="scope.row.service_type == 2">
                            水蜡深度清洗
                        </div>
                        <div v-if="scope.row.service_type == 3">
                            自助水蜡深度精洗
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="actual_paid" label="实付金额(元)" align="center">
                </el-table-column>
                <el-table-column prop="rebate_price" label="返佣金额(元)" align="center">
                </el-table-column>
                <el-table-column prop="add_time" label="返佣时间" align="center">
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination background layout="prev, pager, next" :total="total" @current-change="handleCurrentChange">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import {
    allianceBusinessdec,
    shopList,
    changeallianceBusinesspassword,
    delallianceBusiness,
} from "@/api/api";
export default {
    data() {
        return {
            swval: false,
            changepassword: false,
            shopview: false,
            shopsearchview: "",
            newpassword: "",
            id: this.$route.query.id,
            p: 1,
            shopP: 1,
            totalshop: 0,
            total: 0,
            form: {
                nickname: "",
                franchisee_phone: "",
                add_time: "",
                username: "",
                totalRevenue: "",
                franchisee_money: "",
                franchisee_img: "",
            },
            tableData: [],
            storetableData: [],
        };
    },
    created() {
        this.getallianceBusinessdec();
    },
    methods: {
        // 门店详情
        toshopdec(id) {
            this.$router.push({
                path: "/shop/shopindex/editshop",
                query: { id: id },
            });
        },
        // 加盟商详情
        async getallianceBusinessdec() {
            try {
                const admin_id = sessionStorage.getItem("admin_id");
                const res = await allianceBusinessdec({
                    admin_id,
                    jid: this.id,
                    p: this.p,
                });
                console.log(res);
                this.form.franchisee_img = res.data.franchisee_img;
                this.form.franchisee_money = res.data.franchisee_money;
                this.form.franchisee_phone = res.data.franchisee_phone;
                this.form.nickname = res.data.nickname;
                this.form.totalRevenue = res.data.totalRevenue;
                this.form.username = res.data.username;
                this.form.add_time = res.data.add_time;
                this.tableData = res.data.orderRebateList;
                this.total = Number(res.data.orderRebateCount);
            } catch (error) {
                console.log(error);
            }
        },
        // 修改密码
        async changeword() {
            try {
                const admin_id = sessionStorage.getItem("admin_id");
                const res = await changeallianceBusinesspassword({
                    admin_id,
                    jid: this.id,
                    pwd: this.newpassword,
                });
                console.log(res);
                this.$message({
                    type: "success",
                    message: "修改成功！",
                });
                setTimeout(() => {
                    this.changepassword = false;
                    this.newpassword = "";
                }, 500);
            } catch (error) {
                this.$message({
                    type: "error",
                    message: error.msg,
                });
            }
        },
        // 查看门店
        async openshop() {
            this.shopview = true;
            try {
                const admin_id = sessionStorage.getItem("admin_id");
                const res = await shopList({
                    admin_id,
                    franchisee: this.id,
                    p: this.shopP,
                });
                console.log(res);
                this.storetableData = res.data.list;
                this.totalshop = Number(res.data.count);
            } catch (error) {
                console.log(error);
            }
        },
        // 门店清除
        async clear() {
            this.shopsearchview = "";
            try {
                const admin_id = sessionStorage.getItem("admin_id");
                this.shopP = 1;
                const res = await shopList({
                    admin_id,
                    franchisee: this.id,
                    p: this.shopP,
                });
                console.log(res);
                this.storetableData = res.data.list;
                this.totalshop = Number(res.data.count);
            } catch (error) {
                console.log(error);
            }
        },
        // 门店搜索
        async search() {
            try {
                const admin_id = sessionStorage.getItem("admin_id");
                const res = await shopList({
                    admin_id,
                    franchisee: this.id,
                    p: this.shopP,
                    store_name: this.shopsearchview,
                });
                console.log(res);
                this.storetableData = res.data.list;
                this.totalshop = Number(res.data.count);
            } catch (error) {
                console.log(error);
            }
        },
        // 删除加盟商
        async del() {
            try {
                const admin_id = sessionStorage.getItem("admin_id");
                const res = await delallianceBusiness({ admin_id, jid: this.id });
                console.log(res);
                setTimeout(() => {
                    this.$message({
                        type: "success",
                        message: "删除成功！",
                    });
                    this.$router.back();
                }, 500);
            } catch (error) {
                console.log(error);
            }
        },
        async handleCurrentChangeshop(val) {
            this.shopP = val;
            try {
                const admin_id = sessionStorage.getItem("admin_id");
                const res = await shopList({
                    admin_id,
                    franchisee: this.id,
                    p: this.shopP,
                });
                console.log(res);
                this.storetableData = res.data.list;
                this.totalshop = Number(res.data.count);
            } catch (error) {
                console.log(error);
            }
        },
        //分页
        handleCurrentChange(val) {
            this.p = val;
            this.getallianceBusinessdec();
        },
    },
};
</script>

<style lang="scss" scoped>
.mainbox {
    min-height: 80vh;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    padding: 20px;
    box-sizing: border-box;
    position: relative;

    .dectitle {
        display: flex;
        justify-content: space-between;

        .titletext {
            font-size: 16px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 400;
            color: #000000;
        }

        .titlebtns {
            display: flex;

            .del {
                width: 92px;
                height: 36px;
                background: #ffffff;
                opacity: 1;
                border: 1px solid #ff5733;
                font-size: 14px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 400;
                color: #ff5733;
                line-height: 36px;
                cursor: pointer;
                margin-right: 12px;
                text-align: center;
                border-radius: 5px;
            }

            .submit {
                width: 92px;
                height: 36px;
                background: #0073ff;
                opacity: 1;
                font-size: 14px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                line-height: 36px;
                cursor: pointer;
                text-align: center;
                border-radius: 5px;
            }
        }

        margin-bottom: 20px;
    }

    .decitembox {
        display: flex;
        // justify-content: space-between;
        flex-wrap: wrap;
        padding-bottom: 20px;
        border-bottom: 1px solid #eeeeee;

        .decitem {
            width: 25%;
            display: flex;
            align-items: center;
            margin-bottom: 17px;

            .label {
                font-size: 14px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.85);
                line-height: 22px;
                margin-right: 6px;
            }

            .dec {
                display: flex;
                align-items: center;
                font-size: 14px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 400;
                color: rgba(0, 0, 0, 0.65);

                img {
                    width: 24px;
                    height: 24px;
                    border-radius: 50%;
                    margin-right: 4px;
                }

                .dectext {
                    font-size: 14px;
                    font-family: PingFang SC-Medium, PingFang SC;
                    font-weight: 400;
                    color: #666666;
                    margin-right: 6px;
                }
            }

            .decbtn {
                font-size: 14px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 400;
                color: #0073ff;
                line-height: 22px;
                cursor: pointer;
            }
        }
    }

    .shopviewheader {
        display: flex;
        justify-content: space-between;
        padding-bottom: 10px;
        border-bottom: 1px solid #eeeeee;

        .shopviewtitle {
            font-size: 16px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #000000;
            line-height: 22px;
        }

        .shopviewsearchbox {
            display: flex;
            padding-right: 25px;

            .searchshopviewbtns {
                display: flex;

                .clear {
                    width: 70px;
                    height: 28px;
                    background: #dddddd;
                    border-radius: 4px 4px 4px 4px;
                    opacity: 1;
                    margin-left: 6px;
                    font-size: 12px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 28px;
                    text-align: center;
                    cursor: pointer;
                }

                .search {
                    width: 70px;
                    height: 28px;
                    background: #0073ff;
                    border-radius: 4px 4px 4px 4px;
                    opacity: 1;
                    font-size: 12px;
                    font-family: PingFang SC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #ffffff;
                    line-height: 28px;
                    text-align: center;
                    cursor: pointer;
                    margin-left: 6px;
                }
            }
        }
    }

    .recordtitle {
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 22px;
        margin-top: 20px;
        margin-bottom: 20px;
    }

    .userinfo {
        display: flex;

        img {
            width: 24px;
            height: 24px;
            border-radius: 50%;
            margin-right: 6px;
        }
    }

    .storenamediv {
        display: flex;
        padding-left: 20px;
        align-items: center;
    }

    .storenametext {
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-left: 8px;
    }

    .handle {
        display: flex;
        justify-content: center;
        padding-left: 20px;
    }

    .storeimg {
        width: 46px;
        height: 46px;
    }

    .el-table {
        width: 100%;
    }

    .edit {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #2b6df9;
        line-height: 20px;
        margin-right: 6px;
        cursor: pointer;
    }

    ::v-deep .el-table thead tr>th {
        background-color: #f5f5f5;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #000000;
        line-height: 20px;
    }

    ::v-deep .el-dialog__header {
        .el-input {
            width: 242px;
            height: 28px;
            background: #ffffff;
            border-radius: 4px 4px 4px 4px;

            .el-input__inner {
                width: 100%;
                height: 100%;
            }

            .el-input__icon {
                line-height: 28px;
            }
        }
    }

    ::v-deep .el-dialog__body {
        text-align: center;
        padding-bottom: 60px;
        position: relative;

        .el-pagination {
            position: absolute;
            bottom: 20px;
            left: 0%;
        }
    }

    ::v-deep .el-pagination {
        position: absolute;
        bottom: 20px;
        right: 0;
    }

    ::v-deep .el-pagination.is-background .el-pager li:not(.disabled) {
        background-color: #fff; // 进行修改未选中背景和字体// color: #fff;
        border: 1px solid rgba(0, 0, 0, 0.15);
    }

    ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
        background-color: #1890ff; // 进行修改选中项背景和字体color: #fff;
    }
}
</style>
