<template>
  <!-- 超时费管理 -->
  <div class="container">
    <div class="mainbox">
      <div class="maintitle">超时费管理</div>
      <div class="inputbox">
        <div class="inputlabel">免费时长</div>
        <el-input placeholder="请输入" v-model="free_duration"></el-input>
        <div class="inputlabel">分钟</div>
      </div>
      <div class="inputbox">
        <div class="inputlabel">超时费用</div>
        <el-input placeholder="请输入" v-model="minutes_price"></el-input>
        <div class="inputlabel">元/分钟</div>
      </div>
      <div class="btns">
        <div class="cancel" @click="cancel">重置</div>
        <div class="submit" @click="submit">保存</div>
      </div>
    </div>
  </div>
</template>

<script>
import { overtimepaydec, editovertimepay } from "@/api/api";
export default {
  data() {
    return {
      free_duration: "",
      minutes_price: "",
    };
  },
  created() {
    this.getovertimepaydec();
  },
  methods: {
    async getovertimepaydec() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await overtimepaydec({ admin_id });
        console.log(res);
        this.free_duration = res.data[0].content;
        this.minutes_price = res.data[1].content;
      } catch (error) {
        console.log(error);
      }
    },
    cancel() {
      (this.minutes_price = ""), (this.free_duration = "");
    },
    async submit() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await editovertimepay({
          admin_id,
          free_duration: this.free_duration,
          minutes_price: this.minutes_price,
        });
        console.log(res);
        this.$message({
          type: "success",
          message: "保存成功！",
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  min-height: 80vh;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
  .maintitle {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #000000;
    margin-bottom: 20px;
  }
  .inputbox {
    display: flex;
    margin-bottom: 24px;
    .inputlabel {
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 36px;
    }
  }

  .btns {
    display: flex;
    position: absolute;
    bottom: 24px;
    left: 45%;
    .cancel {
      width: 92px;
      height: 36px;
      background: #ffffff;
      opacity: 1;
      border: 1px solid #dddddd;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #dddddd;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
      border-radius: 5px;
    }
    .submit {
      margin-left: 12px;
      width: 92px;
      height: 36px;
      background: #0073ff;
      opacity: 1;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
      border-radius: 5px;
    }
  }
}

::v-deep .el-input {
  width: 320px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  margin-left: 20px;
  margin-right: 8px;
}
</style>
