<template>
    <!-- 加盟商管理 -->
    <div class="container">
      <div class="mainbox">
        <div class="maintitle">加盟商管理</div>
        <div class="inputbox">
          <div class="inputlabel">佣金比例</div>
          <el-input placeholder="请输入"></el-input>
          <div class="inputlabellast">%</div>
        </div>
        <div class="inputbox">
          <div class="inputlabel">角色介绍</div>
          <el-input
            type="textarea"
            :rows="10"
            placeholder="请输入角色介绍"
            v-model="textarea"
            resize="none"
          >
          </el-input>
        </div>
        <!-- 保存 -->
        <div class="submit">保存</div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        textarea: "",
      };
    },
  };
  </script>
  
  <style lang="scss" scoped>
  .mainbox {
    min-height: 80vh;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    box-sizing: border-box;
    padding: 20px;
    .maintitle {
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 22px;
      margin-bottom: 20px;
    }
    .inputbox {
      display: flex;
      margin-bottom: 24px;
      .inputlabel {
        width: 70px;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        margin-right: 20px;
        line-height: 36px;
      }
      .inputlabellast {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
        margin-left: 8px;
      }
    }
  
    .submit {
      width: 92px;
      height: 36px;
      background: #0073ff;
      opacity: 1;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
      border-radius: 5px;
      margin-left: 90px;
    }
  }
  
  ::v-deep > .el-input {
    width: 320px;
    height: 36px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
  }
  
  ::v-deep .el-textarea {
    width: 576px;
    height: 248px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
  }
  </style>
  