<template>
  <!-- 用户详情 -->
  <div class="container">
    <div class="mainbox">
      <div class="decheader">
        <div class="dectitle">用户详情</div>
        <div class="btns">
          <div class="freeze" v-if="user_status == 1" @click="toFrozen">
            冻结用户
          </div>
          <div class="relieve" v-else @click="noFrozen">解除冻结</div>
        </div>
      </div>
      <div class="decitembox">
        <div class="decitem">
          <div class="label">用户昵称：</div>
          <div class="dec">
            <img :src="user_img" alt="" v-if="user_img ==
            'https://thirdwx.qlogo.cn/mmopen/vi_32/POgEwh4mIHO4nibH0KlMECNjjGxQUq24ZEaGT4poC6icRiccVGKSyXwibcPq4BWmiaIGuG1icwxaQX6grC9VemZoJ8rg/132'
            " />
            <img :src="user_img" alt="" v-else />
            {{ nickname }}
          </div>
        </div>
        <div class="decitem">
          <div class="label">手机号码：</div>
          <div class="dec">{{ user_phone }}</div>
        </div>
        <div class="decitem">
          <div class="label">账户余额(元):</div>
          <div class="dec">{{ balance }}</div>
          <div class="decbtn" @click="changepassword = true">修改</div>
        </div>
        <div class="decitem">
          <div class="label">角色身份：</div>
          <div class="dec" v-if="is_attache == 1">推荐官</div>
          <div class="dec" v-else-if="is_vip == 1">会员</div>
          <div class="dec" v-else>普通用户</div>
        </div>
        <div class="decitem" v-if="is_agecard == 1">
          <div class="label">年卡有效期：</div>
          <div class="dec">2024-07-06</div>
        </div>
        <div class="decitem" v-if="is_agecard == 1">
          <div class="label">账户佣金(元):</div>
          <div class="dec">1</div>
        </div>
        <!-- <div class="decitem">
                    <div class="label">绑定门店：</div>
                    <div class="dec">
                        <div class="storename">
                            <div v-if="store_name == null">暂未绑定门店</div>
                            <div v-else>{{ store_name }}</div>
                        </div>
                    </div>
                </div> -->
        <div class="decitem">
          <div class="label">推荐官：</div>
          <div class="dec" @click="change">
            <el-switch v-model="swval" active-color="#13ce66" inactive-color="#C5C5C5">
            </el-switch>
          </div>
        </div>
        <div class="decitem">
          <div class="label">福利官：</div>
          <div class="dec">
            <div class="flgbtn" @click="openshop">设置福利官</div>
          </div>
        </div>
        <!-- 设置福利官门店 -->
        <el-dialog :visible.sync="shopview" width="50%">
          <template slot="title">
            <div class="shopviewheader">
              <div class="shopviewtitle">福利官关联门店</div>
              <div class="shopviewsearchbox">
                <el-input placeholder="请输入门店名称" prefix-icon="el-icon-search" v-model="shopsearchview">
                </el-input>
                <div class="searchshopviewbtns">
                  <div class="clear" @click="clear">清除</div>
                  <div class="search" @click="search">搜索</div>
                </div>
              </div>
            </div>
          </template>
          <el-table :data="storetableData">
            <el-table-column label="门店名称">
              <template slot-scope="scope">
                <div class="storenamediv">
                  <img :src="$const.fileUrl + scope.row.store_img
            " alt="" class="storeimg" />
                  <div class="storenametext">{{ scope.row.store_name }}</div>
                </div>
              </template>
            </el-table-column>
            <el-table-column prop="store_address" label="地址" show-overflow-tooltip align="center">
            </el-table-column>
            <el-table-column prop="add_time" label="创建时间" align="center">
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <div class="handle">
                  <div class="edit" @click="toshopdec(scope.row.id)">查看</div>
                  <div class="edit">/</div>
                  <div class="edit" @click="setflg(scope.row.id)">关联</div>
                </div>
              </template>
            </el-table-column>
          </el-table>
          <!-- 分页 -->
          <el-pagination background layout="prev, pager, next" :total="totalshop" @current-change="handleCurrentChange">
          </el-pagination>
        </el-dialog>
      </div>
      <!-- 修改余额 -->
      <el-dialog title="修改余额" :visible.sync="changepassword" width="30%" center>
        <el-input placeholder="请输入修改后余额" v-model="newpassword"></el-input>
        <span slot="footer" class="dialog-footer">
          <el-button @click="changepassword = false">取 消</el-button>
          <el-button type="primary" @click="changeword">确 定</el-button>
        </span>
      </el-dialog>
      <!-- 返佣记录 -->
      <div v-if="is_attache == 1">
        <div class="recordtitle">返佣记录</div>
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="name" label="下单用户">
            <template slot-scope="scope">
              <div class="userinfo">
                <img :src="scope.row.user_img" alt="" />
                <div>{{ scope.row.nickname }}</div>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="user_phone" label="手机号码" align="center">
          </el-table-column>
          <el-table-column prop="service_type" label="服务类型" align="center">
            <template slot-scope="scope">
              <div v-if="scope.row.service_type == 1">快速清洗</div>
              <div v-if="scope.row.service_type == 2">水蜡深度清洗</div>
              <div v-if="scope.row.service_type == 3">自助水蜡深度精洗</div>
            </template>
          </el-table-column>
          <el-table-column prop="actual_paid" label="实付金额(元)" align="center">
          </el-table-column>
          <el-table-column prop="rebate_price" label="返佣金额(元)" align="center">
          </el-table-column>
          <el-table-column prop="add_time" label="返佣时间" align="center">
          </el-table-column>
        </el-table>
        <!-- 分页 -->
        <el-pagination background layout="prev, pager, next" :total="total" @current-change="handleCurrentChange">
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import {
  userDec,
  openZY,
  changeuserbalance,
  shopList,
  openFLG,
  Frozen,
} from "@/api/api";
export default {
  data() {
    return {
      swval: false,
      tableData: [],
      id: this.$route.query.id,
      balance: "",
      nickname: "",
      newpassword: "",
      user_phone: "",
      total: 0,
      p: 1,
      is_vip: "",
      is_attache: "",
      user_img: "",
      is_agecard: "",
      changepassword: false,
      type: 0,
      shopview: false,
      shopsearchview: "",
      shopP: 1,
      totalshop: 0,
      user_status: 0,
      storetableData: [],
      store_name: "",
    };
  },
  created() {
    this.getuserDec();
  },
  methods: {
    // 冻结用户
    toFrozen() {
      this.$confirm("此操作将冻结该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const admin_id = sessionStorage.getItem("admin_id");
          const res = await Frozen({
            user_id: this.id,
            type: 1,
          });
          console.log(res);
          this.getuserDec();
          this.$message({
            type: "success",
            message: "冻结成功!",
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    // 解冻用户
    noFrozen() {
      this.$confirm("此操作将解冻该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const admin_id = sessionStorage.getItem("admin_id");
          const res = await Frozen({
            user_id: this.id,
            type: 2,
          });
          console.log(res);
          this.getuserDec();
          this.$message({
            type: "success",
            message: "解冻成功!",
          });
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    // 查看门店
    async openshop() {
      if (this.swval == true) {
        this.$message({
          type: "warning",
          message: "该角色为推荐官，不可设置福利官！",
        });
      } else {
        this.shopview = true;
        try {
          const admin_id = sessionStorage.getItem("admin_id");
          const res = await shopList({
            admin_id,
            p: this.shopP,
          });
          console.log(res);
          this.storetableData = res.data.list;
          this.totalshop = Number(res.data.count);
        } catch (error) {
          console.log(error);
        }
      }
    },

    // 门店清除
    async clear() {
      this.shopsearchview = "";
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        this.shopP = 1;
        const res = await shopList({
          admin_id,
          p: this.shopP,
        });
        console.log(res);
        this.storetableData = res.data.list;
        this.totalshop = Number(res.data.count);
      } catch (error) {
        console.log(error);
      }
    },
    // 门店搜索
    async search() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await shopList({
          admin_id,
          p: this.shopP,
          store_name: this.shopsearchview,
        });
        console.log(res);
        this.storetableData = res.data.list;
        this.totalshop = Number(res.data.count);
      } catch (error) {
        console.log(error);
      }
    },
    // 查看门店
    // 门店详情
    toshopdec(id) {
      this.$router.push({
        path: "/shop/shopindex/editshop",
        query: { id: id },
      });
    },
    setflg(store_id) {
      this.$confirm("此操作将设定此用户为福利官, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          const admin_id = sessionStorage.getItem("admin_id");
          const res = await openFLG({
            user_id: this.id,
            store_id: store_id,
          });
          console.log(res);
          this.$message({
            type: "success",
            message: "设置成功!",
          });
          this.shopview = false;
        })
        .catch(() => {
          // this.$message({
          //   type: "info",
          //   message: "已取消删除",
          // });
        });
    },
    async getuserDec() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await userDec({ admin_id, user_id: this.id });
        console.log(res, "用户信息详情");
        this.nickname = res.data.nickname;
        this.user_phone = res.data.user_phone;
        this.balance = res.data.balance;
        this.is_vip = res.data.is_vip;
        this.is_attache = res.data.is_attache;
        this.user_img = res.data.user_img;
        this.tableData = res.data.orderRebateList;
        this.is_agecard = res.data.is_agecard;
        this.store_name = res.data.store_name;
        this.user_status = res.data.user_status;
        this.total = Number(res.data.orderRebateCount);
        if (res.data.is_attache == 1) {
          this.swval = true;
        } else {
          this.swval = false;
        }
      } catch (error) {
        console.log(error);
      }
    },
    async change() {
      try {
        let status = 0;
        if (this.swval == true) {
          status = 1;
        } else {
          status = 2;
        }
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await openZY({
          admin_id,
          user_id: this.id,
          status: status,
        });
        console.log(res);
        this.$message({
          message: "设置推荐官成功！",
          type: "success",
        });
      } catch (error) {
        console.log(error);
      }
    },
    //分页
    handleCurrentChange(val) {
      this.p = val;
      this.getuserDec();
    },
    // 修改余额
    async changeword() {
      if (this.newpassword <= 0) {
        this.$message({
          message: "修改余额须大于零！",
          type: "error",
        });
      } else if (this.newpassword > this.balance) {
        this.type = 1;
        this.newpassword = this.newpassword - this.balance;
        if (this.newpassword == 0) {
          this.$message({
            message: "修改余额等于当前余额！",
            type: "warning",
          });
        } else {
          try {
            const admin_id = sessionStorage.getItem("admin_id");
            const res = await changeuserbalance({
              admin_id,
              uid: this.id,
              change_price: this.newpassword,
              type: this.type,
            });
            this.$message({
              message: "修改余额成功！",
              type: "success",
            });
            this.changepassword = false;
            this.getuserDec();
            console.log(res, "修改成功");
          } catch (error) {
            console.log(error);
          }
        }
      } else if (this.newpassword < this.balance) {
        this.type = 2;
        this.newpassword = this.balance - this.newpassword;
        if (this.newpassword == 0) {
          this.$message({
            message: "修改余额等于当前余额！",
            type: "warning",
          });
        } else {
          try {
            const admin_id = sessionStorage.getItem("admin_id");
            const res = await changeuserbalance({
              admin_id,
              uid: this.id,
              change_price: this.newpassword,
              type: this.type,
            });
            this.$message({
              message: "修改余额成功！",
              type: "success",
            });
            this.changepassword = false;
            this.getuserDec();
            console.log(res, "修改成功");
          } catch (error) {
            console.log(error);
          }
        }
      } else if (this.newpassword == this.balance) {
        this.$message({
          message: "修改余额等于当前余额！",
          type: "warning",
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  min-height: 80vh;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  padding: 20px;
  box-sizing: border-box;
  position: relative;

  .decheader {
    display: flex;
    justify-content: space-between;
  }

  .btns {
    display: flex;
  }

  .freeze {
    width: 92px;
    height: 36px;
    background: #ffffff;
    opacity: 1;
    border: 1px solid #0073ff;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #0073ff;
    line-height: 36px;
    cursor: pointer;
    margin-right: 12px;
    text-align: center;
    border-radius: 5px;
  }

  .relieve {
    width: 92px;
    height: 36px;
    background: #ffffff;
    opacity: 1;
    border: 1px solid #ff5733;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #ff5733;
    line-height: 36px;
    cursor: pointer;
    margin-right: 12px;
    text-align: center;
    border-radius: 5px;
  }

  .dectitle {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #000000;
    margin-bottom: 20px;
  }

  .decitembox {
    display: flex;
    // justify-content: space-between;
    flex-wrap: wrap;
    padding-bottom: 20px;
    border-bottom: 1px solid #eeeeee;

    .decitem {
      width: 25%;
      display: flex;
      align-items: center;
      margin-bottom: 17px;

      .label {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.85);
        line-height: 22px;
      }

      .dec {
        .storename {
          width: 150px;
          white-space: nowrap;
          /* 防止文本换行 */
          overflow: hidden;
          /* 文本溢出容器时隐藏 */
          text-overflow: ellipsis;
          /* 使用省略号表示被隐藏的文本 */
        }

        .flgbtn {
          width: 80px;
          height: 34px;
          background: #0073ff;
          border-radius: 352px 352px 352px 352px;
          opacity: 1;
          font-size: 12px;
          font-family: PingFang SC, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 34px;
          text-align: center;
          cursor: pointer;
        }

        display: flex;
        align-items: center;
        font-size: 14px;
        font-family: PingFang SC-Medium,
        PingFang SC;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.65);

        img {
          width: 24px;
          height: 24px;
          border-radius: 50%;
          margin-right: 4px;
        }
      }

      .decbtn {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #0073ff;
        line-height: 22px;
        cursor: pointer;
        margin-left: 8px;
      }
    }
  }

  .recordtitle {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 22px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .userinfo {
    display: flex;

    img {
      width: 24px;
      height: 24px;
      border-radius: 50%;
      margin-right: 6px;
    }
  }

  .shopviewheader {
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    border-bottom: 1px solid #eeeeee;

    .shopviewtitle {
      font-size: 16px;
      font-family: PingFang SC-Regular, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 22px;
    }

    .shopviewsearchbox {
      display: flex;
      padding-right: 25px;

      .searchshopviewbtns {
        display: flex;

        .clear {
          width: 70px;
          height: 28px;
          background: #dddddd;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          margin-left: 6px;
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 28px;
          text-align: center;
          cursor: pointer;
        }

        .search {
          width: 70px;
          height: 28px;
          background: #0073ff;
          border-radius: 4px 4px 4px 4px;
          opacity: 1;
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          line-height: 28px;
          text-align: center;
          cursor: pointer;
          margin-left: 6px;
        }
      }
    }
  }

  .storenamediv {
    display: flex;
    padding-left: 20px;
    align-items: center;
  }

  .storenametext {
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    margin-left: 8px;
  }

  .handle {
    display: flex;
    justify-content: center;
    padding-left: 20px;
  }

  .storeimg {
    width: 46px;
    height: 46px;
  }

  .el-table {
    width: 100%;
  }

  .edit {
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #2b6df9;
    line-height: 20px;
    margin-right: 6px;
    cursor: pointer;
  }

  ::v-deep .el-table thead tr>th {
    background-color: #f5f5f5;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 20px;
  }

  ::v-deep .el-dialog__header {
    .el-input {
      width: 242px;
      height: 28px;
      background: #ffffff;
      border-radius: 4px 4px 4px 4px;

      .el-input__inner {
        width: 100%;
        height: 100%;
      }

      .el-input__icon {
        line-height: 28px;
      }
    }
  }

  ::v-deep .el-dialog__body {
    text-align: center;
    padding-bottom: 60px;
    position: relative;

    .el-pagination {
      position: absolute;
      bottom: 20px;
      left: 0%;
    }
  }

  ::v-deep .el-pagination {
    position: absolute;
    bottom: 20px;
    right: 0;
  }

  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff; // 进行修改未选中背景和字体// color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
  }

  ::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #1890ff; // 进行修改选中项背景和字体color: #fff;
  }
}
</style>
