import { render, staticRenderFns } from "./kong.vue?vue&type=template&id=bd1e4236&scoped=true&"
import script from "./kong.vue?vue&type=script&lang=js&"
export * from "./kong.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd1e4236",
  null
  
)

export default component.exports