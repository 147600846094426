<template>
  <!-- 电话客服 -->
  <div class="container">
    <div class="mainbox">
      <div class="maintitle">电话客服</div>
      <div class="inputbox">
        <div class="inputlabel">联系电话</div>
        <el-input placeholder="请输入" v-model="service_tel"></el-input>
      </div>
      <div class="submit" @click="submit">保存</div>
    </div>
  </div>
</template>

<script>
import { phonedec, editphone } from "@/api/api";
export default {
  data() {
    return {
      service_tel: "",
    };
  },
  created() {
    this.getphonedec();
  },
  methods: {
    async getphonedec() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await phonedec({ admin_id });
        console.log(res);
        this.service_tel = res.data[0].content
      } catch (error) {
        console.log(error);
      }
    },
    async submit() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await editphone({
          admin_id,
          service_tel: this.service_tel,
        });
        console.log(res);
        this.$message({
          type: "success",
          message: "保存成功！",
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  min-height: 80vh;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  box-sizing: border-box;
  padding: 20px;
  .maintitle {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #000000;
    margin-bottom: 20px;
  }
  .inputbox {
    display: flex;
    .inputlabel {
      margin-right: 20px;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 36px;
    }
  }
  .submit {
    width: 92px;
    height: 36px;
    background: #0073ff;
    opacity: 1;
    margin-top: 24px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    text-align: center;
    line-height: 36px;
    margin-left: 75px;
    border-radius: 5px;
    cursor: pointer;
  }
}

::v-deep .el-input {
  width: 320px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
}
</style>
