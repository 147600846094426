<template>
  <!-- 订单详情 -->
  <div class="container">
    <div class="mainbox">
      <!-- 基本信息 -->
      <div class="basicbox">
        <div class="basictitle">基本信息</div>
        <div class="infobox">
          <div class="infoitem">
            <div class="label">用户昵称：</div>
            <div class="dec">{{ nickname }}</div>
          </div>
          <div class="infoitem">
            <div class="label">手机号码：</div>
            <div class="dec">{{ user_phone }}</div>
          </div>
          <div class="infoitem">
            <div class="label">下单门店：</div>
            <div class="dec">{{ store_name }}</div>
          </div>
          <div class="infoitem">
            <div class="label">门店地址：</div>
            <div class="dec">{{ store_address }}</div>
          </div>
        </div>
      </div>
      <!-- 订单信息 -->
      <div class="orderinfobox">
        <div class="orderinfotitle">订单信息</div>
        <div class="infobox">
          <div class="infoitem">
            <div class="label">订单编号：</div>
            <div class="dec">{{ order_sn }}</div>
          </div>
          <div class="infoitem">
            <div class="label">服务类型：</div>
            <div class="dec" v-if="is_append_shuila == 0">快洗</div>
            <div class="dec" v-if="is_append_shuila == 1">精洗</div>
          </div>
          <div class="infoitem">
            <div class="label">下单时间：</div>
            <div class="dec">{{ add_time }}</div>
          </div>
          <div class="infoitem">
            <div class="label">服务单价(元)：</div>
            <div class="dec">{{ account_payable }}</div>
          </div>
          <div class="infoitem">
            <div class="label">超时时长：</div>
            <div class="dec">{{ overTime }}分钟</div>
          </div>
          <div class="infoitem">
            <div class="label">超时费用(元)：</div>
            <div class="dec">{{ overPrice }}</div>
          </div>
          <div class="infoitem">
            <div class="label">卡券：</div>
            <div class="dec orange">-{{ coupon_price }}元</div>
          </div>
          <div class="infoitem">
            <div class="label">实付金额(元)：</div>
            <div class="dec">{{ actual_paid }}</div>
          </div>
          <div class="infoitem">
            <div class="label">门店收益(元)：</div>
            <div class="dec">{{ store_profit }}</div>
          </div>
          <div class="infoitem">
            <div class="label">完成时间：</div>
            <div class="dec">{{ completion_time }}</div>
          </div>
          <div class="infoitem">
            <div class="label">支付方式：</div>
            <div class="dec" v-if="pay_type == 1">微信支付</div>
            <div class="dec" v-if="pay_type == 2">余额支付</div>
            <div class="dec" v-if="pay_type == 3">洗车卡抵扣</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { orderDec } from "@/api/api";
export default {
  data() {
    return {
      id: this.$route.query.id,
      account_payable: "",
      actual_paid: "",
      add_time: "",
      card_price: "",
      completion_time: "",
      coupon_price: "",
      nickname: "",
      order_sn: "",
      overPrice: "",
      overTime: "",
      pay_type: "",
      service_type: "",
      store_address: "",
      store_name: "",
      user_phone: "",
      store_profit: "",
      is_append_shuila: ""
    };
  },
  created() {
    this.getorderDec();
  },
  methods: {
    async getorderDec() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await orderDec({ admin_id, order_id: this.id });
        console.log(res);
        (this.account_payable = res.data.account_payable),
          (this.actual_paid = res.data.actual_paid),
          (this.add_time = res.data.add_time),
          (this.card_price = res.data.card_price),
          (this.completion_time = res.data.completion_time),
          (this.coupon_price = res.data.coupon_price),
          (this.nickname = res.data.nickname),
          (this.order_sn = res.data.order_sn),
          (this.overPrice = res.data.overPrice),
          (this.overTime = res.data.overTime),
          (this.pay_type = res.data.pay_type),
          (this.service_type = res.data.service_type),
          (this.store_address = res.data.store_address),
          (this.store_name = res.data.store_name),
          (this.user_phone = res.data.user_phone);
        this.store_profit = res.data.store_profit;
        this.is_append_shuila = res.data.is_append_shuila
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  min-height: 80vh;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  box-sizing: border-box;
  padding: 20px;

  .basicbox {
    .basictitle {
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #000000;
      margin-bottom: 20px;
    }

    .infobox {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      padding-bottom: 20px;
      border-bottom: 1px solid #eeeeee;

      .infoitem {
        width: 25%;
        display: flex;

        .label {
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
        }

        .dec {
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }
    }
  }

  .orderinfobox {
    .orderinfotitle {
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #000000;
      margin-bottom: 20px;
      margin-top: 20px;
    }

    .infobox {
      display: flex;
      flex-wrap: wrap;

      //   justify-content: space-between;
      .infoitem {
        width: 25%;
        margin-bottom: 12px;
        display: flex;

        .orange {
          color: #ff5733 !important;
        }

        .label {
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 400;
          color: rgba(0, 0, 0, 0.85);
        }

        .dec {
          font-size: 14px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 400;
          color: #666666;
        }
      }
    }
  }
}
</style>
