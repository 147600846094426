<template>
  <div id="main">
    <el-container>
      <el-aside>
        <iAside></iAside>
      </el-aside>
      <el-container>
        <!-- 头部区域 -->
        <el-header>
          <Header></Header>
        </el-header>
        <!-- main区域 -->
        <el-main>
          <router-view />
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import Header from "@/layout/components/navbar.vue";
import iAside from "@/layout/components/sidebar.vue";
export default {
  components: { Header, iAside },
  watch: {
    //监听路由变化
    $route(to, from) {
      if (this.$router.path != to.path) {
        window.sessionStorage.setItem("activePath", to.path);
      }
      // console.log(to.path, from.path);
      // to , from 分别表示从哪跳转到哪，都是一个对象
      // to.path  ( 表示的是要跳转到的路由的地址 eg: /home );
    },
  },
  methods: {},
};
</script>

<style scoped>
#main {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
}

::v-deep .el-aside {
  width: 185px !important;
}
.el-header,
.el-footer {
  background-color: #fff;
  color: #333;
  text-align: center;
  line-height: 60px;
}
.el-header {
  height: 120px !important;
}
.el-aside {
  color: #fff;
  text-align: left;
  line-height: 210px;
}

.el-main {
  height: 87vh;
  background: #f0f4fa;
  color: #333;
}

body > .el-container {
  margin-bottom: 40px;
}

/* .el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
} */

::v-deep .el-container {
  height: 100%;
}
</style>
