<template>
    <!-- 城市代理管理 -->
    <div class="container">
      <div class="mainbox">
        <div class="maintitle">城市代理管理</div>
        <div class="inputbox">
          <div class="inputlabel">佣金比例</div>
          <el-input placeholder="请输入" v-model="agency_rebate"></el-input>
          <div class="inputlabellast">%</div>
        </div>
        <!-- 保存 -->
        <div class="submit" @click="submit">保存</div>
      </div>
    </div>
  </template>
  
  <script>
  import {cityAgencyinfo,editcityAgency} from '@/api/api'
  export default {
    data() {
      return {
        agency_rebate:""
      };
    },
    created() {
      this.getcityAgencyinfo()
    },
    methods:{
      async getcityAgencyinfo() {
        try {
          const admin_id = sessionStorage.getItem("admin_id")
          const res = await cityAgencyinfo({admin_id})
          console.log(res);
          this.agency_rebate = Number(res.data[0].content) *100
        } catch (error) {
          console.log(error);
        }
      },
      async submit() {
        try {
          const admin_id = sessionStorage.getItem("admin_id")
          const res = await editcityAgency({
            admin_id,agency_rebate:Number(this.agency_rebate) / 100
          })
          console.log(res);
          this.$message({
          type: "success",
          message: "保存成功！",
        });
        } catch (error) {
          console.log(error);
        }
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .mainbox {
    min-height: 80vh;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    box-sizing: border-box;
    padding: 20px;
    .maintitle {
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 22px;
      margin-bottom: 20px;
    }
    .inputbox {
      display: flex;
      margin-bottom: 24px;
      .inputlabel {
        width: 70px;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        margin-right: 20px;
        line-height: 36px;
      }
      .inputlabellast {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #333333;
        line-height: 36px;
        margin-left: 8px;
      }
    }
  
    .submit {
      width: 92px;
      height: 36px;
      background: #0073ff;
      opacity: 1;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
      border-radius: 5px;
      margin-left: 90px;
    }
  }
  
  ::v-deep > .el-input {
    width: 320px;
    height: 36px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
  }
  </style>
  