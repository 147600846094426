<template>
  <!-- 服务管理 -->
  <div class="container">
    <div class="mainbox">
      <div class="servicetitle">服务管理</div>
      <div class="waterWaxwashbox">
        <div class="textbox titleleft">服务价格</div>
        <el-input placeholder="请输入" v-model="service_price"></el-input>
        <div class="textbox midmargin">元，会员价</div>
        <el-input placeholder="请输入" v-model="service_vip_price"></el-input>
        <div class="textbox">元</div>
      </div>
      <div class="waterWaxwashbox">
        <div class="textbox titleleft">次卡、年卡抵扣金额</div>
        <el-input placeholder="请输入" v-model="service_card_price"></el-input>
        <div class="textbox">元</div>
      </div>
      <div class="waterWaxwashbox">
        <div class="textbox titleleft">精洗额外费用</div>
        <el-input placeholder="请输入" v-model="append_shuila_price"></el-input>
        <div class="textbox">元</div>
      </div>
      <div class="yearpricebox">
        <div class="textbox titleleft">服务时间</div>
        <el-input placeholder="请输入" v-model="car_wash_time"></el-input>
        <div class="textbox">分</div>
      </div>
      <div class="servicetitle">超时费管理</div>
      <div class="waterWaxwashbox">
        <div class="textbox titleleft">免费时长</div>
        <el-input placeholder="请输入" v-model="free_duration"></el-input>
        <div class="textbox">分</div>
      </div>
      <div class="waterWaxwashbox">
        <div class="textbox titleleft">超时费用</div>
        <el-input placeholder="请输入" v-model="minutes_price"></el-input>
        <div class="textbox">元</div>
      </div>
      <!-- <div class="waterWaxwashbox">
        <div class="textbox titleleft">水蜡深度清洗价格</div>
        <el-input
          placeholder="请输入"
          v-model="service_shuila_price"
        ></el-input>
        <div class="textbox midmargin">元，会员价</div>
        <el-input
          placeholder="请输入"
          v-model="service_shuila_vip_price"
        ></el-input>
        <div class="textbox">元</div>
      </div>
      <div class="artificialwashbox">
        <div class="textbox titleleft">自助水蜡深度精洗价格</div>
        <el-input
          placeholder="请输入"
          v-model="service_rengong_price"
        ></el-input>
        <div class="textbox midmargin">元，会员价</div>
        <el-input
          placeholder="请输入"
          v-model="service_rengong_vip_price"
        ></el-input>
        <div class="textbox">元</div>
      </div> -->
      <div class="btnsbox">
        <div class="reset" @click="reset">重置</div>
        <div class="submit" @click="editservice">保存</div>
      </div>
    </div>
  </div>
</template>
<script>
import { service, serviceedit } from "@/api/api";
export default {
  data() {
    return {
      service_price: '',
      service_vip_price: '',
      car_wash_time: '',
      append_shuila_price: '',
      free_duration: '',
      minutes_price: '',
      service_card_price: ''
    };
  },
  created() {
    this.getservice();
  },
  methods: {
    // 服务管理
    async getservice() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await service({ admin_id });
        console.log(res);
        this.service_price = res.data[11].content;
        this.service_vip_price = res.data[12].content;
        this.car_wash_time = res.data[10].content;
        this.append_shuila_price = res.data[9].content;
        this.free_duration = res.data[7].content;
        this.minutes_price = res.data[8].content;
        this.service_card_price = res.data[2].content;
      } catch (error) {
        console.log(error);
      }
    },
    // 服务编辑
    async editservice() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await serviceedit({
          admin_id,
          service_price: this.service_price,
          service_vip_price: this.service_vip_price,
          car_wash_time: this.car_wash_time,
          append_shuila_price: this.append_shuila_price,
          free_duration: this.free_duration,
          minutes_price: this.minutes_price,
          service_card_price: this.service_card_price,
        });
        this.$message({
          type: "success",
          message: "保存成功！",
        });
        console.log(res);
      } catch (error) {
        this.$message({
          type: "error",
          message: error.msg,
        });
      }
    },
    // 重置
    reset() {
      (this.service_kuaixi_price = ""), //快洗价格
        (this.service_kuaixi_vip_price = ""), //快洗会员价
        (this.service_card_price = ""), //年次卡金额
        (this.service_shuila_price = ""), //水蜡价格
        (this.service_shuila_vip_price = ""), //水蜡会员价
        (this.service_rengong_price = ""), //人工价格
        (this.service_rengong_vip_price = ""); //半自助人工精洗会员价/元
    },
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  min-height: 80vh;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  box-sizing: border-box;
  padding: 20px;
  position: relative;

  .servicetitle {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #000000;
    margin-bottom: 20px;
  }

  .fastwashbox {
    display: flex;
    align-items: center;
  }

  .titleleft {
    min-width: 122px;
    text-align: right;
    margin-right: 20px;
  }

  .midmargin {
    margin-right: 8px;
  }

  .textbox {
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #333333;
  }

  .yearpricebox {
    display: flex;
    align-items: center;
    margin-top: 24px;
    border-bottom: 1px solid #eeeeee;
    padding-bottom: 24px;
    margin-bottom: 24px;
  }

  .waterWaxwashbox {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }

  .artificialwashbox {
    display: flex;
    align-items: center;
  }

  .btnsbox {
    position: absolute;
    bottom: 24px;
    left: 40%;
    display: flex;
    justify-content: center;

    .reset {
      width: 92px;
      height: 36px;
      background: #ffffff;
      opacity: 1;
      border: 1px solid #dddddd;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #dddddd;
      line-height: 36px;
      text-align: center;
      margin-right: 12px;
      cursor: pointer;
    }

    .submit {
      width: 92px;
      height: 36px;
      background: #0073ff;
      opacity: 1;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
    }
  }
}

::v-deep .el-input {
  width: 210px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  margin-right: 8px;
}
</style>
