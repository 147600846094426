<template>
  <!-- 单次卡管理 -->
  <div class="container">
    <router-view v-if="this.$route.path == '/promotion/timecard/newcard' ||
      this.$route.path == '/promotion/timecard/carddec' || this.$route.path == '/promotion/timecard/yearcardadd'
      "></router-view>
    <div class="mainbox" v-else>
      <div class="maintitle">卡管理</div>
      <div class="searchbox">
        卡名称：
        <el-input placeholder="请输入卡名称" prefix-icon="el-icon-search" v-model="name">
        </el-input>
        <div class="searchbtns">
          <div class="clear" @click="clear">清除</div>
          <div class="search" @click="search">搜索</div>
        </div>
      </div>
      <div v-if="type == '2'" class="newcard" @click="toaddonecard">新建单次卡</div>
      <div v-if="type == '1'" class="newcard" @click="toaddyearcard">生成多次卡</div>
      <!-- 单次卡列表 -->
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="card_name" label="名称" align="center">
        </el-table-column>
        <el-table-column prop="card_days" label="使用有效期(天)" align="center">
        </el-table-column>
        <el-table-column v-if="type == '1'" prop="card_num" label="可使用次数" align="center">
        </el-table-column>
        <el-table-column v-if="type == '2'" prop="is_infinite" label="可领取人数" align="center">
          <template slot-scope="scope">
            <div>
              {{ scope.row.is_infinite == -1 ? '无限' : scope.row.is_infinite }}
            </div>
          </template>
        </el-table-column>
        <el-table-column v-if="type == '1'" prop="card_code" label="兑换码" align="center">
        </el-table-column>
        <el-table-column v-if="type == '2'" prop="code_img" label="二维码" align="center">
          <template slot-scope="scope">
            <div class="codebox">
              <img :src="$const.fileUrl + scope.row.code_img" alt="" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="use_status" label="状态" align="center">
          <template slot-scope="scope">
            <div class="normal" v-if="scope.row.use_status == 1">正常</div>
            <div class="stop" v-if="scope.row.use_status == 2">停用</div>
          </template>
        </el-table-column>
        <el-table-column prop="handle" label="操作" align="center">
          <template slot-scope="scope">
            <template>
              <div class="handle">
                <div class="edit" @click="toeditcard(scope.row.id)">编辑</div>
                <div>/</div>
                <div class="del" @click="change(scope.row.id, scope.row.use_status)" v-if="scope.row.use_status == 1">
                  停用
                </div>
                <div class="on" @click="change(scope.row.id, scope.row.use_status)" v-if="scope.row.use_status == 2">
                  启用
                </div>
              </div>
            </template>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background layout="prev, pager, next" :total="total" @current-change="handleCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { timecardList, changetimecard } from "@/api/api";
export default {
  data() {
    return {
      p: 1,
      total: 0,
      name: "",
      type: this.$route.query.type,
      tableData: [],
    };
  },
  watch: {
    $route(to, from) {
      if (to.name === "timecard") {
        this.type = this.$route.query.type;
        this.gettimecardList();
      }
    },
  },
  created() {
    this.gettimecardList();
  },
  methods: {
    // 新建单次卡
    toaddonecard() {
      this.$router.push("/promotion/timecard/newcard");
    },
    toaddyearcard() {
      this.$router.push("/promotion/timecard/yearcardadd");
    },
    // 编辑单次卡
    toeditcard(id) {
      this.$router.push({
        path: "/promotion/timecard/carddec",
        query: { id: id, type: this.type },
      });
    },
    // 获取单次卡列表
    async gettimecardList() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        console.log("type", this.type);
        const res = await timecardList({ admin_id, p: this.p, type: this.type });
        console.log(res);
        this.total = Number(res.data.count);
        this.tableData = res.data.list;
      } catch (error) {
        console.log(error);
      }
    },
    // 次卡启用停用
    async change(id, status) {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        if (status == 1) {
          status = 2;
        } else if (status == 2) {
          status = 1;
        }
        const res = await changetimecard({ admin_id, cid: id, status: status });
        console.log(res);
        this.gettimecardList();
      } catch (error) {
        console.log(error);
      }
    },
    // 清除
    clear() {
      this.name = ''
      this.gettimecardList()
    },
    // 搜索
    async search() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await timecardList({
          admin_id,
          p: this.p,
          type: this.type,
          name: this.name,
        });
        console.log(res);
        this.total = Number(res.data.count);
        this.tableData = res.data.list;
      } catch (error) {
        console.log(error);
      }
    },
    //分页
    handleCurrentChange(val) {
      this.p = val;
      this.gettimecardList();
    },
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  min-height: 80vh;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  box-sizing: border-box;
  padding: 20px;
  position: relative;

  .maintitle {
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 25px;
    margin-bottom: 20px;
  }

  .searchbox {
    display: flex;
    // justify-content: space-a;
    padding-bottom: 20px;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 20px;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 36px;

    .searchbtns {
      display: flex;
      margin-left: 100px;

      .clear {
        width: 78px;
        height: 36px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #dddddd;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #dddddd;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
      }

      .search {
        width: 78px;
        height: 36px;
        background: #0073ff;
        border-radius: 4px 4px 4px 4px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
        text-align: center;
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }

  .newcard {
    width: 88px;
    height: 36px;
    background: #0073ff;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 36px;
    text-align: center;
    cursor: pointer;
    margin-bottom: 20px;
  }
}

::v-deep .el-input {
  width: 240px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
}

.handle {
  display: flex;
  justify-content: center;
  padding-left: 20px;
}

.codebox>img {
  width: 46px;
  height: 46px;
}

.el-table {
  width: 100%;
}

.normal {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #00af5a;
  line-height: 20px;
}

.stop {
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ff5733;
  line-height: 20px;
}

.edit {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 400;
  color: #2b6df9;
  line-height: 20px;
  margin-right: 6px;
  cursor: pointer;
}

.del {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 400;
  color: #ff5733;
  line-height: 20px;
  margin-left: 6px;
  cursor: pointer;
}

.on {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 400;
  color: #2b6df9;
  line-height: 20px;
  margin-left: 6px;
  cursor: pointer;
}

::v-deep .el-table thead tr>th {
  background-color: #f5f5f5;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
}

::v-deep .el-table {
  margin-bottom: 50px;
}

::v-deep .el-pagination {
  position: absolute;
  bottom: 20px;
  right: 0;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled) {
  background-color: #fff; // 进行修改未选中背景和字体// color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #1890ff; // 进行修改选中项背景和字体color: #fff;
}
</style>
