<template>
    <div class="container">
        <div class="mainbox">
            <div class="addtitle">新建代理</div>
            <el-form :model="form" :rules="rules" ref="ruleForm" class="demo-ruleForm" :hide-required-asterisk="true">
                <el-form-item label="登录账号" prop="username">
                    <el-input v-model="form.username" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="登录密码" prop="password">
                    <el-input v-model="form.password" type="password" placeholder="请输入"></el-input>
                </el-form-item>
                <el-form-item label="代理头像" prop="avator">
                    <el-upload class="avatar-uploader" action="/api/6510eb6963781" :show-file-list="false"
                        :on-success="handleAvatarSuccess" :before-upload="beforeAvatarUpload">
                        <img v-if="form.avator" :src="$const.fileUrl + form.avator" class="addimg" />
                        <img src="../../assets/shopimg/add.png" alt="" class="addimg" v-else />
                    </el-upload>
                </el-form-item>
                <el-form-item label="代理昵称" prop="name">
                    <el-input v-model="form.name" placeholder="请输入"></el-input>
                </el-form-item>
                <!--联动选择省份后选择城市-->
                <el-form-item label="代理城市：">
                    <el-col :span="3">
                        <el-form-item prop="selectProv">
                            <el-select v-model="selectProv" placeholder="请选择省份" v-on:change="getShiList(selectProv)"
                                v-loadmore="shengloadmore">
                                <el-option v-for="item in provs" :label="item.name" :value="item.id" :key="item.code">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                    <el-col :span="3">
                        <el-form-item prop="selectCity">
                            <el-select v-if="selectProv != ''" v-model="selectCity" placeholder="请选择城市"
                                v-loadmore="shiloadmore" @visible-change="changecity">
                                <el-option v-for="item in citys" :label="item.name" :value="item.id" :key="item.code">
                                </el-option>
                            </el-select>
                        </el-form-item>
                    </el-col>
                </el-form-item>
                <el-form-item label="手机号" prop="phone">
                    <el-input v-model="form.phone" placeholder="请输入"></el-input>
                </el-form-item>
            </el-form>
            <!-- 取消、保存 -->
            <div class="btns">
                <div class="cancel" @click="cancel">取消</div>
                <div class="submit" @click="submitForm">保存</div>
            </div>
        </div>
    </div>
</template>

<script>
import { cityList, addcityAgency } from "@/api/api";
export default {
    data() {
        return {
            form: {
                username: "",
                password: "",
                avator: "",
                name: "",
                phone: "",
            },
            provs: [],
            citys: [],
            shengP: 1,
            shiP: 1,
            selectProv: "",
            selectCity: "",
            rules: {
                username: [
                    { required: true, message: "请输入登录账号", trigger: "blur" },
                ],
                password: [
                    { required: true, message: "请输入登录密码", trigger: "blur" },
                ],
                avator: [
                    { required: true, message: "请上传代理头像", trigger: "blur" },
                ],
                name: [{ required: true, message: "请输入代理昵称", trigger: "blur" }],
                phone: [{ required: true, message: "请输入手机号", trigger: "blur" }],
            },
        };
    },
    created() {
        this.getcityList();
    },
    directives: {
        loadmore: {
            inserted(el, binding) {
                const dom = el.querySelector(
                    ".el-select-dropdown .el-select-dropdown__wrap"
                );
                dom.addEventListener("scroll", function (e) {
                    const condition =
                        this.scrollHeight - this.scrollTop <= this.clientHeight;
                    if (condition) {
                        binding.value();
                    }
                });
            },
        },
    },
    watch: {
        selectProv: {
            handler() {
                // this.shengP = 1
                this.shiP = 1
                this.citys = []
            }
        }
    },
    methods: {
        submitForm() {
            this.$refs.ruleForm.validate(async (valid) => {
                if (valid) {
                    if (this.selectProv == "") {
                        this.$message.error("请选择省份");
                    } else if (this.selectCity == "") {
                        this.$message.error("请选择城市");
                    } else {
                        try {
                            const admin_id = sessionStorage.getItem("admin_id");
                            const res = await addcityAgency({
                                admin_id,
                                username: this.form.username,
                                password: this.form.password,
                                user_img: this.form.avator,
                                nickname: this.form.name,
                                user_phone: this.form.phone,
                                sheng_id: this.selectProv,
                                shi_id: this.selectCity,
                            });
                            console.log(res);
                            this.$message({
                                type: "success",
                                message: "保存成功！",
                            });
                            setTimeout(() => {
                                this.$router.back();
                            }, 1000);
                        } catch (error) {
                            console.log(error);
                        }
                    }
                } else {
                    console.log("error submit!!");
                    return false;
                }
            });
        },
        resetForm(formName) {
            this.$refs[formName].resetFields();
        },
        handleAvatarSuccess(res, file) {
            this.form.avator = file.response.data;
        },
        beforeAvatarUpload(file) {
            const isLt2M = file.size / 1024 / 1024 < 2;
            if (!isLt2M) {
                this.$message.error("上传图片大小不能超过 2MB!");
            }
            return isLt2M;
        },
        // 获取省列表
        async getcityList() {
            try {
                const res = await cityList({ pid: 0, p: this.shengP });
                console.log(res);
                this.provs = this.provs.concat(res.data.list);
            } catch (error) {
                console.log(error);
            }
        },
        // 获得市
        async getShiList(shengName) {
            this.$forceUpdate();
            // this.citys = []
            try {
                const res = await cityList({ pid: this.selectProv, p: this.shiP });
                console.log(res);
                this.citys = this.citys.concat(res.data.list);
            } catch (error) {
                console.log(error);
            }
        },
        // 触底方法
        shengloadmore() {
            this.shengP++
            this.getcityList()
        },
        shiloadmore() {
            this.shiP++
            this.getShiList()
        },
        changecity(e) {
            console.log(e);
            if (e) {
                return false
            } else {
                this.shiP = 1
            }
        },
        // 取消
        cancel() {
            this.$router.back();
        },
    },
};
</script>

<style lang="scss" scoped>
.mainbox {
    min-height: 80vh;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    box-sizing: border-box;
    padding: 20px;
    position: relative;

    .addtitle {
        font-size: 16px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #000000;
        margin-bottom: 20px;
    }

    .addimg {
        width: 120px;
        height: 120px;
        cursor: pointer;
    }

    .btns {
        display: flex;
        position: absolute;
        bottom: 24px;
        left: 45%;

        .cancel {
            width: 92px;
            height: 36px;
            background: #ffffff;
            opacity: 1;
            border: 1px solid #dddddd;
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 400;
            color: #dddddd;
            line-height: 36px;
            margin-right: 12px;
            text-align: center;
            border-radius: 8px;
            cursor: pointer;
        }

        .submit {
            width: 92px;
            height: 36px;
            background: #0073ff;
            opacity: 1;
            font-size: 14px;
            font-family: PingFang SC-Medium, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            line-height: 36px;
            text-align: center;
            border-radius: 8px;
            cursor: pointer;
        }
    }
}

::v-deep .el-input {
    width: 420px;
    height: 36px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
}

::v-deep .el-form-item__label {
    width: 100px;
}

::v-deep .el-select {
    width: 146px;
    height: 36px;
    margin-right: 12px;
}

::v-deep .el-select>.el-input {
    width: 100%;
    height: 100%;
}
</style>
