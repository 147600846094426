<template>
  <!-- 轮播管理 -->
  <router-view v-if="this.$route.path == '/other/carousel/addcarousel' ||
    this.$route.path == '/other/carousel/carouseldec'
    "></router-view>
  <div class="container" v-else>
    <div class="mainbox">
      <div class="maintitle">轮播图管理</div>
      <div class="searchbox">
        <div class="searchinputbox">
          <div class="inputlabel">轮播图名称</div>
          <el-input placeholder="请输入轮播图名称" prefix-icon="el-icon-search" v-model="name">
          </el-input>
        </div>
        <div class="searchbtns">
          <div class="clear" @click="clear">清除</div>
          <div class="search" @click="search">搜索</div>
        </div>
      </div>
      <!-- 新建轮播 -->
      <div class="newcarousel" @click="tonewcarousel">新建轮播</div>
      <!-- 轮播列表 -->
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="banner_title" label="轮播图名称"> </el-table-column>
        <el-table-column prop="banner_img" label="轮播图素材" align="center">
          <template slot-scope="scope">
            <div>
              <img class="carousel_img" :src="$const.fileUrl + scope.row.banner_img" alt="" />
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="banner_sort" label="排序" align="center">
        </el-table-column>
        <el-table-column prop="add_time" label="创建时间" align="center">
        </el-table-column>
        <el-table-column prop="handel" label="操作" align="center">
          <template slot-scope="scope">
            <div class="orderdec" @click="tocarouseldec(scope.row.id)">查看</div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background layout="prev, pager, next" :total="total" @current-change="handleCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { carouselList } from "@/api/api";
export default {
  data() {
    return {
      tableData: [],
      total: 0,
      name: "",
      p: 1
    };
  },
  watch: {
    $route(to, from) {
      if (to.name === "carousel") {
        this.getcarouselList();
      }
    },
  },
  created() {
    this.getcarouselList();
  },
  methods: {
    // 新建轮播
    tonewcarousel() {
      this.$router.push("/other/carousel/addcarousel");
    },
    // 轮播详情
    tocarouseldec(id) {
      this.$router.push({ path: "/other/carousel/carouseldec", query: { id: id } });
    },
    // 获取轮播列表
    async getcarouselList() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await carouselList({ admin_id, p: this.p });
        console.log(res);
        this.tableData = res.data.list;
        this.total = Number(res.data.count);
      } catch (error) {
        console.log(error);
      }
    },
    // 搜索
    async search() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await carouselList({ admin_id, p: this.p, name: this.name });
        console.log(res);
        this.tableData = res.data.list;
        this.total = Number(res.data.count);
      } catch (error) {
        console.log(error);
      }
    },
    // 清除
    clear() {
      this.name = ''
      this.getcarouselList()
    },
    //分页
    handleCurrentChange(val) {
      this.p = val;
      this.getcarouselList();
    },
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  min-height: 80vh;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
}

.maintitle {
  font-size: 16px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 400;
  color: #000000;
  margin-bottom: 20px;
}

.searchbox {
  display: flex;
  padding-bottom: 20px;
  border-bottom: 1px solid #eeeeee;

  .searchinputbox {
    display: flex;

    .inputlabel {
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 36px;
      margin-right: 8px;
    }
  }
}

.searchbtns {
  display: flex;
  margin-left: 65px;

  .clear {
    width: 78px;
    height: 36px;
    border-radius: 4px 4px 4px 4px;
    opacity: 1;
    border: 1px solid #dddddd;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #dddddd;
    line-height: 36px;
    text-align: center;
    margin-right: 16px;
    cursor: pointer;
  }

  .search {
    width: 78px;
    height: 36px;
    background: #0073ff;
    border-radius: 4px 4px 4px 4px;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 36px;
    text-align: center;
    cursor: pointer;
  }
}

.newcarousel {
  width: 88px;
  height: 36px;
  background: #0073ff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #ffffff;
  line-height: 36px;
  text-align: center;
  cursor: pointer;
  margin-top: 20px;
  margin-bottom: 20px;
}

.carousel_img {
  width: 200px;
  height: 80px;
  border-radius: 4px 4px 4px 4px;
  object-fit: fill;
}

.orderdec {
  font-size: 14px;
  font-family: PingFang SC-Medium, PingFang SC;
  font-weight: 400;
  color: #2b6df9;
  cursor: pointer;
}

::v-deep .el-input {
  width: 240px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
}

::v-deep .el-input {
  width: 240px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
}

::v-deep .el-table thead tr>th {
  background-color: #f5f5f5;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
}

::v-deep .el-pagination {
  position: absolute;
  bottom: 20px;
  right: 0;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled) {
  background-color: #fff; // 进行修改未选中背景和字体// color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #1890ff; // 进行修改选中项背景和字体color: #fff;
}
</style>
