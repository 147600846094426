<template>
  <!-- 新建洗车卡 -->
  <div class="container">
    <div class="mainbox">
      <div class="maintitle">新建洗车卡</div>
      <div class="inputbox">
        <div class="inputlabel">洗车卡名称</div>
        <el-input placeholder="请输入洗车卡名称" v-model="name"></el-input>
      </div>
      <div class="inputbox">
        <div class="inputlabel">有效日期</div>
        <el-input placeholder="请输入有效日期" v-model="day"></el-input>
        <div class="inputlabel">天</div>
      </div>
      <div class="inputbox">
        <div class="inputlabel">可领取人数</div>
        <el-input placeholder="请输入可使用人数" v-model="is_infinite"></el-input>
        <div class="inputlabel">人 </div>
      </div>
      <div class="inputlabel" style="color: red; font-size: 14px;">注：-1代表不限制人数</div>
      <div class="btns">
        <div class="cancel" @click="cancel">取消</div>
        <div class="submit" @click="submit">保存</div>
      </div>
    </div>
  </div>
</template>

<script>
import { addtimecard } from "@/api/api";
export default {
  data() {
    return {
      name: "",
      day: "",
      is_infinite: "",
    };
  },
  methods: {
    async submit() {
      try {
        if (this.name == "") {
          this.$message({
            type: "error",
            message: "请输入洗车卡名称"
          })
        } else if (this.day == "") {
          this.$message({
            type: "error",
            message: "请输入有效日期"
          })
        } else {
          const admin_id = sessionStorage.getItem("admin_id");
          const res = await addtimecard({
            admin_id,
            name: this.name,
            day_num: this.day,
            is_infinite: this.is_infinite,
          });
          console.log(res);
          this.$message({
            type: "success",
            message: "保存成功！",
          });
          setTimeout(() => {
            this.$router.back();
          }, 500);
        }
      } catch (error) {
        console.log(error);
      }
    },
    cancel() {
      this.$router.back()
    }
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  min-height: 80vh;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  box-sizing: border-box;
  padding: 20px;
  position: relative;

  .maintitle {
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #000000;
    margin-bottom: 20px;
  }

  .inputbox {
    display: flex;
    margin-bottom: 24px;

    .inputlabel {
      width: 70px;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 36px;
      margin-right: 20px;
    }
  }

  .btns {
    display: flex;
    position: absolute;
    bottom: 24px;
    left: 45%;

    .cancel {
      width: 92px;
      height: 36px;
      background: #ffffff;
      opacity: 1;
      border: 1px solid #dddddd;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #dddddd;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
    }

    .submit {
      margin-left: 12px;
      width: 92px;
      height: 36px;
      background: #0073ff;
      opacity: 1;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
      text-align: center;
      cursor: pointer;
    }
  }
}

::v-deep {
  .el-input {
    margin-right: 8px;
    width: 320px;
    height: 36px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
  }
}
</style>
