<template>
    <!-- 承包商列表 -->
    <div class="container">
        <router-view
            v-if="this.$route.path == '/people/contractorList/contractordec' || this.$route.path == '/people/contractorList/addcontractor'"></router-view>
        <div class="mainbox" v-else>
            <div class="peopletitle">承包商列表</div>
            <div class="searchbox">
                <div class="seacrchitem">
                    <div class="label">承包商昵称</div>
                    <el-input placeholder="请输入承包商名称" prefix-icon="el-icon-search" v-model="name">
                    </el-input>
                </div>
                <div class="seacrchitem">
                    <div class="label">手机号码</div>
                    <el-input placeholder="请输入用户手机号码" prefix-icon="el-icon-search" v-model="phone">
                    </el-input>
                </div>
                <div class="seacrchitem">
                    <div class="label">关联门店</div>
                    <el-input placeholder="请输入门店名称" prefix-icon="el-icon-search" v-model="store_name">
                    </el-input>
                </div>
                <div class="btns">
                    <div class="clear" @click="clear">清除</div>
                    <div class="search" @click="search">搜索</div>
                </div>
            </div>
            <div class="addbtn" @click="addcontractor">新建承包商</div>
            <!-- 用户列表 -->
            <el-table :data="tableData" style="width: 100%">
                <el-table-column prop="name" label="承包商昵称" align="center">
                    <template slot-scope="scope">
                        <div class="userinfo">
                            <img :src="$const.fileUrl + scope.row.contractor_img" alt="" />
                            {{ scope.row.nickname }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="contractor_phone" label="手机号码" align="center">
                </el-table-column>
                <el-table-column prop="username" label="登录账户" align="center">
                </el-table-column>
                <el-table-column prop="" label="总计收益(元)" align="center">
                    <template slot-scope="scope">
                        <div v-if="scope.row.earnings == null">
                            0
                        </div>
                        <div v-else>
                            {{ scope.row.earnings }}
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="add_time" label="创建时间" align="center">
                </el-table-column>
                <el-table-column prop="handle" label="操作" align="center">
                    <template slot-scope="scope">
                        <div class="peopledec" @click="tocontractordec(scope.row.id)">查看</div>
                    </template>
                </el-table-column>
            </el-table>
            <!-- 分页 -->
            <el-pagination background layout="prev, pager, next" :total="total" @current-change="handleCurrentChange">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import { contractorList } from '@/api/api'
export default {
    data() {
        return {
            value: "",
            tableData: [],
            p: 1,
            name: '',
            phone: '',
            store_name: '',
            total: 0
        };
    },
    created() {
        this.getcontractorList()
    },
    watch: {
        $route(to, from) {
            if (to.name === "contractorList") {
                this.getcontractorList();
            }
        },
    },
    methods: {
        //承包商详情
        tocontractordec(id) {
            this.$router.push({
                path: "/people/contractorList/contractordec", query: {
                    id: id
                }
            });
        },
        // 新建承包商
        addcontractor() {
            this.$router.push("/people/contractorList/addcontractor")
        },
        // 获取承包商列表
        async getcontractorList() {
            try {
                const admin_id = sessionStorage.getItem("admin_id")
                const res = await contractorList({ admin_id, p: this.p })
                console.log(res);
                this.tableData = res.data.list
                this.total = Number(res.data.count)
            } catch (error) {
                console.log(error);
            }
        },
        // 清除
        clear() {
            this.name = '',
                this.phone = '',
                this.store_name = ''
            this.getcontractorList()
        },
        // 搜索
        async search() {
            try {
                const admin_id = sessionStorage.getItem("admin_id")
                const res = await contractorList({ admin_id, p: this.p, name: this.name, phone: this.phone, store_name: this.store_name })
                console.log(res);
                this.tableData = res.data.list
            } catch (error) {
                console.log(error);
            }
        },
        //分页
        handleCurrentChange(val) {
            this.p = val;
            this.getcontractorList();
        },
    },
};
</script>

<style lang="scss" scoped>
.mainbox {
    min-height: 88vh;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
    box-sizing: border-box;
    padding: 20px;
    position: relative;

    .peopletitle {
        font-size: 18px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #000000;
        margin-bottom: 20px;
    }

    .searchbox {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 20px;
        border-bottom: 1px solid #eeeeee;
        margin-bottom: 20px;

        .seacrchitem {
            display: flex;
            align-items: center;

            .label {
                font-size: 14px;
                font-family: PingFang SC-Medium, PingFang SC;
                font-weight: 400;
                color: #000000;
                margin-right: 8px;
            }
        }

        .btns {
            display: flex;

            .clear {
                width: 78px;
                height: 36px;
                border-radius: 4px 4px 4px 4px;
                opacity: 1;
                border: 1px solid #dddddd;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #dddddd;
                line-height: 36px;
                text-align: center;
                cursor: pointer;
            }

            .search {
                width: 78px;
                height: 36px;
                background: #0073ff;
                border-radius: 4px 4px 4px 4px;
                font-size: 14px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                line-height: 36px;
                text-align: center;
                margin-left: 8px;
                cursor: pointer;
            }
        }
    }

    .addbtn {
        width: 88px;
        height: 36px;
        background: #0073ff;
        border-radius: 4px 4px 4px 4px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
        text-align: center;
        margin-bottom: 20px;
        cursor: pointer;
    }

    .userinfo {
        // display: flex;
        align-items: center;

        img {
            width: 24px;
            height: 24px;
            margin-right: 6px;
            border-radius: 50%;
            vertical-align: middle;
        }
    }

    .peopledec {
        color: #0073ff;
        cursor: pointer;
    }
}

::v-deep .el-input {
    width: 240px;
    height: 36px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
}

::v-deep .el-input {
    width: 240px;
    height: 36px;
    background: #ffffff;
    border-radius: 4px 4px 4px 4px;
}

::v-deep .el-table thead tr>th {
    background-color: #f5f5f5;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #000000;
    line-height: 20px;
}

::v-deep .el-pagination {
    position: absolute;
    bottom: 20px;
    right: 0;
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled) {
    background-color: #fff; // 进行修改未选中背景和字体// color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
}

::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
    background-color: #1890ff; // 进行修改选中项背景和字体color: #fff;
}
</style>
