<template>
  <!-- 用户列表 -->
  <div class="container">
    <router-view
      v-if="this.$route.path == '/people/peopleList/peopledec'"
    ></router-view>
    <div class="mainbox" v-else>
      <div class="peopletitle">用户列表</div>
      <div class="searchbox">
        <div class="seacrchitem">
          <div class="label">用户昵称</div>
          <el-input placeholder="请输入用户名称" prefix-icon="el-icon-search" v-model="searchform.name">
          </el-input>
        </div>
        <div class="seacrchitem">
          <div class="label">手机号码</div>
          <el-input
            placeholder="请输入用户手机号码"
            prefix-icon="el-icon-search"
            v-model="searchform.phone"
          >
          </el-input>
        </div>
        <div class="seacrchitem">
          <div class="label">角色身份</div>
          <el-select placeholder="请选择" v-model="searchform.role">
            <el-option
              v-for="item in roleoptions"
              :key="item.label"
              :label="item.label"
              :value="item.value"
            >
            </el-option>
          </el-select>
        </div>
        <div class="btns">
          <div class="clear" @click="clear">清除</div>
          <div class="search" @click="search">搜索</div>
        </div>
      </div>
      <!-- 用户列表 -->
      <el-table :data="tableData" style="width: 100%">
        <el-table-column prop="id" label="用户ID" align="center">
        </el-table-column>
        <el-table-column prop="nickname" label="用户昵称" align="center">
        </el-table-column>
        <el-table-column prop="user_phone" label="手机号码" align="center">
        </el-table-column>
        <el-table-column prop="role" label="角色身份" align="center">
          <template slot-scope="scope">
            <div v-if="scope.row.is_vip == 1 && scope.row.is_attache == 2 && scope.row.is_agecard == 0">
              会员
            </div>
            <div v-else-if="scope.row.is_agecard == 1 && scope.row.is_attache == 2">
              年卡会员
            </div>
            <div v-else-if="scope.row.is_attache == 1">
              推荐官
            </div>
            <div v-else>
              普通用户
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="balance" label="账户余额(元)" align="center">
        </el-table-column>
        <el-table-column prop="handle" label="操作" align="center">
          <template slot-scope="scope">
            <div class="peopledec" @click="topeopledec(scope.row.id)">查看</div>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background layout="prev, pager, next" :total="total" @current-change="handleCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>
import { userList } from "@/api/api";
export default {
  data() {
    return {
      roleoptions: [
        {
          label: "普通用户",
          value: 1,
        },
        {
          label: "年卡用户",
          value: 4,
        },
        {
          label: "会员",
          value: 2,
        },
        {
          label: "推荐官",
          value: 3,
        },
      ],
      p: 1,
      tableData: [],
      total: 0,
      searchform: {
        name: "",
        phone: "",
        role: "",
      },
    };
  },
  created() {
    this.getuserList();
  },
  methods: {
    // 用户详情
    topeopledec(id) {
      this.$router.push({path:"/people/peopleList/peopledec",query:{id:id}});
    },
    // 获取用户列表
    async getuserList() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await userList({ admin_id, p: this.p });
        console.log(res);
        this.tableData = res.data.list;
        this.total = Number(res.data.count);
      } catch (error) {
        console.log(error);
      }
    },
    async search() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await userList({
          admin_id,
          p: this.p,
          nickname: this.searchform.name,
          phone: this.searchform.phone,
          role: this.searchform.role,
        });
        console.log(res);
        this.tableData = res.data.list
        this.total = Number(res.data.count);
      } catch (error) {
        console.log(error);
      }
    },
    clear() {
      this.searchform = {
        name: "",
        phone: "",
        role: "",
      };
      this.getuserList()
    },
     //分页
     handleCurrentChange(val) {
      this.p = val;
      this.getuserList();
    },
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  min-height: 80vh;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  box-sizing: border-box;
  padding: 20px;
  position: relative;
  .peopletitle {
    font-size: 18px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #000000;
    margin-bottom: 20px;
  }
  .searchbox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px;
    border-bottom: 1px solid #eeeeee;
    margin-bottom: 20px;
    .seacrchitem {
      display: flex;
      align-items: center;
      .label {
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 400;
        color: #000000;
        margin-right: 8px;
      }
    }
    .btns {
      display: flex;
      .clear {
        width: 78px;
        height: 36px;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        border: 1px solid #dddddd;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #dddddd;
        line-height: 36px;
        text-align: center;
        cursor: pointer;
      }
      .search {
        width: 78px;
        height: 36px;
        background: #0073ff;
        border-radius: 4px 4px 4px 4px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 36px;
        text-align: center;
        margin-left: 8px;
        cursor: pointer;
      }
    }
  }
  .peopledec {
    color: #0073ff;
    cursor: pointer;
  }
}
::v-deep .el-input {
  width: 240px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
}

::v-deep .el-input {
  width: 240px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
}
::v-deep .el-table thead tr > th {
  background-color: #f5f5f5;
  font-size: 14px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #000000;
  line-height: 20px;
}
::v-deep .el-pagination {
  position: absolute;
  bottom: 20px;
  right: 0;
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled) {
  background-color: #fff; // 进行修改未选中背景和字体// color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.15);
}
::v-deep .el-pagination.is-background .el-pager li:not(.disabled).active {
  background-color: #1890ff; // 进行修改选中项背景和字体color: #fff;
}
</style>
