<template>
  <!-- 福利官管理 -->
  <div class="container">
    <div class="mainbox">
      <!-- 福利官管理 -->
      <div class="welfarebox">
        <div class="welfaretitle">福利官管理</div>
        <div class="inputbox">
          <div class="inputlabel">开通价格</div>
          <el-input placeholder="请输入" v-model="bonus_open_price"></el-input>
          <div class="inputlabellast">元/年</div>
        </div>
        <div class="inputbox">
          <div class="inputlabel">开通数量</div>
          <el-input placeholder="请输入" v-model="bonus_store_num"></el-input>
          <div class="inputlabellast">人</div>
        </div>
        <div class="inputbox">
          <div class="inputlabel">开通须知</div>
          <el-input
            type="textarea"
            :rows="10"
            placeholder="请输入开通须知"
            v-model="bonus_desc"
            resize="none"
          >
          </el-input>
        </div>
      </div>
      <!-- 年卡设置 -->
      <div class="setbox">
        <div class="yearcardtitle">赠送设置</div>
        <div class="inputbox">
          <div class="inputlabel">年卡次数</div>
          <el-input placeholder="请输入" v-model="bonus_card_num"></el-input>
          <div class="inputlabellast">次</div>
        </div>
        <div class="inputbox">
        <div class="inputlabel">赠送有效期</div>
        <el-input placeholder="请输入" v-model="bonus_card_day"></el-input>
        <div class="inputlabellast">天</div>
      </div>
        <!-- <div class="inputbox">
          <div class="inputlabel">优惠券金额</div>
          <el-input
            placeholder="请输入"
            v-model="bonus_coupon_amount"
          ></el-input>
          <div class="inputlabellast">元/张，赠送数量</div>
          <el-input placeholder="请输入" v-model="bonus_coupon_num"></el-input>
          <div class="inputlabellast">张</div>
        </div> -->
      </div>

      <!-- <div class="yearcardtitle">赠送有效期</div>
      <div class="inputbox">
        <div class="inputlabel">赠送有效期</div>
        <el-input placeholder="请输入" v-model="bonus_card_day"></el-input>
        <div class="inputlabellast">天</div>
      </div> -->

      <!-- 保存 -->
      <div class="submit" @click="submit">保存</div>
    </div>
  </div>
</template>

<script>
import { welfareinfo, editwelfare } from "@/api/api";
export default {
  data() {
    return {
      bonus_open_price: "", //福利开通价格
      bonus_store_num: "", //福利官人数
      bonus_desc: "", //福利官须知
      bonus_card_day: "", //福利官年卡有效期
      bonus_card_num: "", //福利官年卡次数
      // bonus_coupon_amount:"",//福利官优惠券金额
      // bonus_coupon_num:"",//福利官优惠券数量
    };
  },
  created() {
    this.getwelfareinfo();
  },
  methods: {
    async getwelfareinfo() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await welfareinfo({ admin_id });
        console.log(res);
        this.bonus_open_price = res.data[0].content;
        this.bonus_store_num = res.data[1].content;
        this.bonus_desc = res.data[2].content;
        this.bonus_card_day = res.data[3].content;
        this.bonus_card_num = res.data[4].content;
        // this.bonus_coupon_amount = res.data[5].content;
        // this.bonus_coupon_num = res.data[6].content;
      } catch (error) {
        console.log(error);
      }
    },
    async submit() {
      try {
        const admin_id = sessionStorage.getItem("admin_id");
        const res = await editwelfare({
          admin_id,
          bonus_open_price: this.bonus_open_price,
          bonus_store_num: this.bonus_store_num,
          bonus_desc: this.bonus_desc,
          bonus_card_day: this.bonus_card_day,
          bonus_card_num: this.bonus_card_num,
        //   bonus_coupon_amount : this.bonus_coupon_amount,
        // bonus_coupon_num : this.bonus_coupon_num
        });
        console.log(res);
        this.$message({
          type: "success",
          message: "保存成功！",
        });
      } catch (error) {
        console.log(error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.mainbox {
  min-height: 80vh;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
  opacity: 1;
  box-sizing: border-box;
  padding: 20px;
  .welfarebox {
    border-bottom: 1px solid #eeeeee;
    .welfaretitle {
      font-size: 16px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #000000;
      line-height: 22px;
      margin-bottom: 20px;
    }
  }

  .setbox {
    border-bottom: 1px solid #eeeeee;
  }

  .inputbox {
    display: flex;
    margin-bottom: 24px;
    .inputlabel {
      width: 70px;
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      margin-right: 20px;
      line-height: 36px;
    }
    .inputlabellast {
      font-size: 14px;
      font-family: PingFang SC-Medium, PingFang SC;
      font-weight: 400;
      color: #333333;
      line-height: 36px;
      margin-left: 8px;
      margin-right: 8px;
    }
  }

  .yearcardtitle {
    margin-top: 20px;
    font-size: 16px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #000000;
    margin-bottom: 20px;
  }

  .submit {
    width: 92px;
    height: 36px;
    background: #0073ff;
    opacity: 1;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 36px;
    text-align: center;
    cursor: pointer;
    border-radius: 5px;
    margin-left: 90px;
    margin-top: 50px;
  }
}

::v-deep > .el-input {
  width: 320px;
  height: 36px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
}

::v-deep .el-textarea {
  width: 576px;
  height: 248px;
  background: #ffffff;
  border-radius: 4px 4px 4px 4px;
}
</style>
