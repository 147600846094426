<template>
  <div>
    <el-row>
      <el-col :span="24">
        <div class="breadcrumb">
          <el-breadcrumb separator="/">
            <el-breadcrumb-item
              v-for="(bread, index) in bread"
              :key="index"
              :to="bread.path"
            >{{ bread.title }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
export default {
  name: "myBreadCrumb",
  data() {
    return {
      bread: [
      ]
    };
  },
  methods: {
    getBreadData() {
      const meatched = this.$route.matched;
      const filterArr = meatched.filter(item => {
        return item.meta && item.meta.title;
      });

      const newBread = filterArr.map(item => {
        return {
          title: item.meta.title,
          path: item.path
        };
      });
      this.bread = [{path:'/home',title:'首页'},...newBread];
    }
  },
  created() {
    // 面包屑功能实现
    // 1.路由模块化整理成一二级路由
    // 2.每级路由配置路由源信息
    // 3.在面包屑组件获取路由源信息中的meta数据，在matched中可以获取到各级meta信息
    // 4.过滤matched中的meta，过滤掉空的meta
    // 5.循环出我们需要的数据格式
    // 6.过滤好的数据格式赋值给data里面的bread
    // 7.监听路由，路由改变就重新给bread赋值
    this.getBreadData()
  },
  watch: {
    "$route.path": function() {
      this.getBreadData()
    }
  }
};
</script>
<style lang="scss" scoped>
.breadcrumb {
  padding-left: 12px;
}
.el-breadcrumb {
  font-size: 14px;
  line-height: 3;
}
</style>
